/*
  --------------------
  InView
  Generic utility for triggering CSS animations when a container
  is scrolled into view. Uses waypoints.js.

  Required: data-module="in-view"
  Optional: data-offset="percentage|number|bottom-in-view" (any value that waypoints.js accepts - http://imakewebthings.com/waypoints/api/offset-option/)
  --------------------
*/

var Util = require('../utilities');

InView = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

InView.prototype = {

  init: function() {

    // add data attribute to indicate an element is being watched
    this.elem.setAttribute('data-is', 'ready');

    // get optional offset
    var elemOffset = this.elem.getAttribute('data-offset') || '0';

    // add new Waypoint
    new Waypoint({
      element: this.elem,
      handler: function() {
        this.element.setAttribute('data-is', 'visible');
      },
      offset: elemOffset
    });
  }

};

module.exports = InView;
