
// Toggle Functionality
    var Util = require('../utilities');
FunctionalityToggles = function(elem, features){
  this.elem = elem;
  this.features = features;
  this.init();
};

FunctionalityToggles.prototype = {
  init: function() {
    Util.fire(this, ['toggleClick']);
  },

  selected:null,
  toggleClick: function(){
    var selected = null;
    var toggles = this.elem.querySelectorAll('.form-functionality-toggle');

    for (var i = 0, len = toggles.length; i < len; i++) {
      if (toggles[i].classList.contains('-selected')) {
        selected = toggles[i];
      }

      toggles[i].addEventListener('click', function() {
        selected.classList.remove('-selected');
        this.classList.add('-selected');

        selected = this;
      });
    }
  }
};

module.exports = FunctionalityToggles;



