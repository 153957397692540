var Util = require('../utilities');
var Vars = require('../global-variables');
var whatInput = require('what-input');

NavGroup = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

NavGroup.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'toggleSetup', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  isOpen: false,
  mq: window.matchMedia(Vars.mq.navigation),

  setVariables: function() {

    this.guid = Util.guid();

    //
    // dom variables
    //

    this.headingLink = this.elem.querySelector('[data-nav-headinglink]');
    this.subList = this.elem.querySelector('[data-nav-sublist]');
    this.subLinks = this.subList.querySelectorAll('a, button, input, select, textarea');

    //
    // named event handlers for binding/unbinding
    //

    // mouse events
    this.onMouseEnterToggle = this.mouseToggle.bind(this);
    this.onMouseLeaveToggle = this.mouseToggle.bind(this);

    // prevent default tab behavior when moving through sub list
    this.onBlockTab = this.blockTab.bind(this);

    // trap focus within the sub list
    this.onFocusRestrict = this.focusRestrict.bind(this);

  },


  /*
    --------------------
    Set up
    --------------------
  */

  toggleSetup: function() {

    // always set aria expanded
    this.headingLink.setAttribute('aria-expanded', 'false');

    // always reset to data-is="close"
    this.subList.setAttribute('data-is', 'close');

    // always set up tab indexes
    this.toggleTabindex();

    // large screens
    if (this.mq.matches) {

      // only do this once, not for each nav group
      if (!document.body.hasAttribute('data-nav-is')) {
        document.body.setAttribute('data-nav-is', 'close');

        if (this.features.includes('setHeight')) {
          Vars.nav.style.height = this.headingLink.offsetHeight + 'px';
        }
      }

      this.subList.setAttribute(
        'data-columns',
        this.subList.querySelectorAll('.site-nav-subitem').length
      );

      // add desktop event listeners
      this.toggleListeners('addEventListener');

    // small screens
    } else {

      // only do this once, not for each nav group
      if (document.body.hasAttribute('data-nav-is')) {
        document.body.removeAttribute('data-nav-is');

        Vars.nav.style.height = null;
      }

      // remove desktop event listeners
      this.toggleListeners('removeEventListener');
    }
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.mq.addListener(this.toggleSetup.bind(this));

    // touch events to toggle subnav
    this.headingLink.addEventListener('click', this.clickToggle.bind(this));

    // keyboard events
    this.headingLink.addEventListener('keydown', this.blockScroll.bind(this));
    this.subList.addEventListener('keydown', this.blockScroll.bind(this));

    // down arrow opens subnav and focuses first link
    this.headingLink.addEventListener('keyup', this.keyboardOpen.bind(this));

    // pubsub events
    window.pubSub.subscribe('navOpen', function(obj) {
      if (obj.guid !== this.guid) this.toggleNav('close');
    }.bind(this));

    window.pubSub.subscribe('closeSubNav', function() {
      this.toggleNav('close');
    }.bind(this));
  },

  toggleListeners: function(action) {

    // mouse events
    this.elem[action]('mouseenter', this.onMouseEnterToggle);
    this.elem[action]('mouseleave', this.onMouseLeaveToggle);
  },

  clickToggle: function(event) {

    // desktop/laptop w/ touch, only a `touch` event opens the subnav
    if (this.mq.matches) {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // verify touch via what-input
      if (whatInput.ask() === 'touch' || navigator.userAgent.match(/(\(iPod|\(iPhone|\(iPad)/)) {
        window.pubSub.publish('navOpen', {
          guid: this.guid
        });

        this.toggleNav((this.isOpen) ? 'close' : 'open');

        event.preventDefault();
      }

    // on the small screen version, any `click` event opens the subnav
    } else {
      window.pubSub.publish('navOpen', {
        guid: this.guid
      });

      this.toggleNav((this.isOpen) ? 'close' : 'open');

      event.preventDefault();
    }
  },

  mouseToggle: function(event) {

    // make sure event is mouse-initiated via what-input
    // and not side effect of touch events that can fire mouse events
    if (whatInput.ask('loose') === 'mouse') {
      this.toggleNav((event.type === 'mouseenter') ? 'open' : 'close');
    }
  },

  keyboardOpen: function(event) {

    // if arrow down key is used, open the subnav
    // and move focus to first link
    if (event.which === Vars.keys.DOWN) {
      this.toggleNav('open');

      // a small delay is necessary to allow menu to open
      // before setting focus
      setTimeout(function() {
        this.subLinks[0].focus();
      }.bind(this), 300);
    }
  },

  toggleNav: function(state) {
    this.isOpen = (state === 'open') ? true : false;

    document.body.setAttribute('data-nav-is', state);
    this.elem.setAttribute('data-is', state);
    this.subList.setAttribute('data-is', state);
    this.headingLink.setAttribute('aria-expanded', this.isOpen);

    this.toggleTabindex();

    if (this.features.includes('setHeight') && this.mq.matches) {
      var headingHeight = this.headingLink.offsetHeight;
      var newHeight = (this.isOpen) ? (this.subList.offsetHeight + headingHeight) : headingHeight;

      Vars.nav.style.height = newHeight + 'px';
    }

    if (this.mq.matches) this.toggleSubListEvents(
      (state === 'open') ? 'addEventListener' : 'removeEventListener'
    );
  },

  toggleSubListEvents: function(action) {

    // prevent default tab behavior when moving through sub list
    this.subList[action]('keydown', this.onBlockTab);

    // trap focus within the sub list
    this.subList[action]('keyup', this.onFocusRestrict);
  },


  /*
    --------------------
    Utilities
    --------------------
  */

  toggleTabindex: function() {
    var tabSetting = (this.isOpen) ? '0' : '-1';

    for (var i = 0, len = this.subLinks.length; i < len; i++) {
      this.subLinks[i].setAttribute('tabindex', tabSetting);
    }
  },

  // prevents the default behavior of the tab key
  // so it can be manually handled
  blockTab: function(event) {
    if (event.which === Vars.keys.TAB) event.preventDefault();
  },

  // prevents scrolling with arrow keys
  // use case: navigating menu items with arrow keys
  blockScroll: function(event) {
    if (
      event.which === Vars.keys.UP ||
      event.which === Vars.keys.DOWN
    ) event.preventDefault();
  },

  // customized version of focusRestrict to work specifically for subnav
  // includes arrow keys for navigation
  focusRestrict: function(event) {
    var key = event.which;
    var focusable = Array.prototype.slice.call(this.subLinks);
    var listLength = this.subLinks.length;
    var focused = document.activeElement;
    var focusIndex = focusable.indexOf(focused);
    var nextIndex;

    // esc key closes subnav
    if (key === Vars.keys.ESC) {
      this.headingLink.focus();
      this.toggleNav('close');

    // up/down/tab/shift+tab cycles through links
    } else if (
      key === Vars.keys.TAB ||
      key === Vars.keys.UP ||
      key === Vars.keys.DOWN
    ) {
      var direction = ((key === Vars.keys.TAB && event.shiftKey) || key === Vars.keys.UP) ? 'up' : 'down';

      if (focusIndex < (listLength - 1) && direction === 'down') {
        nextIndex = focusIndex + 1;
      } else if (focusIndex > 0 && direction === 'up') {
        nextIndex = focusIndex -1;
      } else if (focusIndex === (listLength - 1) && direction === 'down') {
        nextIndex = 0;
      } else {
        nextIndex = listLength -1;
      }

      focusable[nextIndex].focus();
    }
  }

};

module.exports = NavGroup;
