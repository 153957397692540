var Util = require('../utilities');

AudioPlayer = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

AudioPlayer.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  playerInterval: null,
  progress: {
    block: {
      from: 'rgb(2,126,172)',
      to: 'rgb(1,159,203)'
    },
    inline: {
      from: 'rgb(179,226,240)',
      to: 'rgb(229,245,250)'
    }
  },

  setVariables: function() {
    if (this.features.includes('inline')) {
      // Get the href attribute from the audio resource if the inline audio
      // does not have href.
      if (this.elem.getAttribute('href') == '#inline-audio') {
        var crfName = this.elem.getAttribute('data-crf-name');
        var audioResource = document.querySelectorAll('[data-audio-crf-name]');
        for (i=0; i < audioResource.length; i++) {
          if (audioResource[i].getAttribute(['data-audio-crf-name']) == crfName) {
            this.elem.setAttribute('href', audioResource[i].getAttribute('href'));
            break;
          }
        }
      }
      this.audio = new Audio(this.elem.getAttribute('href'));
    } else {
      this.language = JSON.parse(this.elem.getAttribute('data-language'));
      this.audio = this.elem.querySelector('audio');
    }

    this.progressFrom = this.progress[this.features].from;
    this.progressTo = this.progress[this.features].to;
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {
    if (this.features.includes('block')) this.addButton();
  },

  addButton: function() {
    this.playButton = document.createElement('button');
    this.playButton.textContent = this.language.play;

    this.elem.appendChild(this.playButton);
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.elem.addEventListener('click', this.toggle.bind(this));
  },

  toggle: function(event) {
    this[(this.audio.paused) ? 'play' : 'pause']();

    event.preventDefault();
  },

  play: function() {
    this.audio.play();
    this.elem.setAttribute('data-is', 'playing');

    if (this.features.includes('block')) this.playButton.textContent = this.language.pause;

    this.playerInterval = setInterval(function() {
      var progress = (this.audio.currentTime / this.audio.duration) * 100;

      this.elem.setAttribute(
        'style',
        'background: linear-gradient(90deg, ' + this.progressFrom + ', ' + this.progressFrom + ' ' + progress + '%, ' + this.progressTo + ' ' + progress + '%, ' + this.progressTo + ')'
      );

      if (this.audio.ended) {
        this.pause();
        this.elem.removeAttribute('style');
        this.elem.removeAttribute('data-is');
      }
    }.bind(this), 10);
  },

  pause: function() {
    this.audio.pause();
    this.elem.setAttribute('data-is', 'paused');

    if (this.features.includes('block')) this.playButton.textContent = this.language.play;

    clearInterval(this.playerInterval);
  }

};

module.exports = AudioPlayer;
