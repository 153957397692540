var Util = require('../utilities');

ImageCallout = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

ImageCallout.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'setHeight', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  captionState: true,

  setVariables: function() {
    this.img = this.elem.querySelector('[data-image-callout="image"]');
    this.imgText = this.elem.querySelector('[data-image-callout="text"]');
    this.imgHeight = this.elem.getAttribute('data-image-height');
    this.imgWidth = this.elem.getAttribute('data-image-width');
    this.imgCaption = this.elem.querySelector('[data-image-callout="caption"]');

    this.offsetAttr = this.elem.getAttribute('data-offset');
    this.offset = (this.offsetAttr) ? this.offsetAttr : '40%';

    this.language = (this.imgCaption) ? JSON.parse(this.imgCaption.getAttribute('data-language')) : {};

    this.ratio = this.imgHeight / this.imgWidth;

    // save for comparison on resize
    this.currentWidth = window.innerWidth;
  },


  /*
    --------------------
    Set Up
    --------------------
  */

  setUp: function() {
    if (this.imgCaption) this.addCaptionToggle();
    if (this.imgText) this.setUpWaypoint();
  },

  addCaptionToggle: function() {
    this.captionToggle = document.createElement('button');

    // caption is always visible to screen readers
    // so toggle button should be hidden
    this.captionToggle.setAttribute('aria-hidden', 'true');
    this.captionToggle.classList.add('image-callout-caption-toggle');
    this.captionToggle.textContent = this.language.caption;

    this.imgCaption.appendChild(this.captionToggle);

    this.toggleCaption();
  },

  setUpWaypoint: function() {
    new Waypoint({
      element: this.elem,
      handler: function() {
        this.elem.setAttribute('data-in-view', '');
      }.bind(this),
      offset: this.offset
    });
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    if (this.captionToggle) {
      this.captionToggle.addEventListener('click', this.toggleCaption.bind(this));
    }

    window.pubSub.subscribe('fontsLoaded', this.setHeight.bind(this));
    window.addEventListener('resize', Util.debounce(this.testResize.bind(this), 100));
  },

  testResize: function() {

    // only execut if width changes
    if (window.innerWidth !== this.currentWidth) {
      this.currentWidth = window.innerWidth;
      this.setHeight();
    }
  },

  // container and image sizing
  setHeight: function() {

    // set styles on container
    var newHeight = this.containerHeight();

    this.elem.setAttribute('data-init', '');
    this.elem.style.height = newHeight + 'px';

    // set styles on image
    var imgSizes = this.imageSizes(newHeight);

    if (this.img !== null && typeof this.img !== 'undefined' ) {
      this.img.style.height = imgSizes.height + 'px';
      this.img.style.width = imgSizes.width + 'px';
    }
  },

  containerHeight: function() {
    var newHeight = this.ratio * this.elem.parentElement.clientWidth;

    // don't let container get taller than 90% of the window...
    if (newHeight > (window.innerHeight * 0.9)) {
      newHeight = window.innerHeight * 0.9;
    }
    // Set the height of image-callout div according to the inner content
    // for mobile devices.
    // We are getting the height of just one child div of image-callout
    // considering that image-callouts will have only one sizing container.
    if (window.matchMedia("(max-width: 769px)").matches) {
      var contentHeight = this.elem.querySelector('.u-container').clientHeight;
      if (newHeight < contentHeight) {
        newHeight = contentHeight;
        this.elem.querySelector('.image-callout-img-container').style.display = 'none';
      }
    }

    if (this.imgText) {
      var textHeight = this.imgText.offsetHeight;

      // re-evaluate new height to make sure it's not taller than the text
      if (
        newHeight < textHeight ||
        (this.features.includes('text-center') && !this.features.includes('image-height'))
      ) {
        newHeight = textHeight;
      }
    }

    return newHeight;
  },

  imageSizes: function(h) {
    var parentWidth = this.elem.parentElement.clientWidth;
    var containerRatio = h / parentWidth;

    return {
      'height': (containerRatio < this.ratio) ? (parentWidth * this.ratio) : h,
      'width': (containerRatio < this.ratio) ? parentWidth : (h / this.ratio)
    };
  },

  // caption toggling
  toggleCaption: function() {
    this.captionState = !this.captionState;

    this.elem.setAttribute('data-caption-visible', this.captionState);
  }

};

module.exports = ImageCallout;
