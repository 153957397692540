var Util = require('../utilities');
var whatInput = require('what-input');

Modal = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

Modal.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  classContainer: 'modal-container',
  classClose: 'modal-close',
  classCloseAlt: 'modal-close-alt',
  classContent: 'modal-content',
  isOpen: false,

  setVariables: function() {
    this.contentWrapper = document.querySelector('[data-a11y-content-wrapper]');
    this.elemId = this.elem.getAttribute('id');

    // find all the links that point to this modal
    this.triggers = document.querySelectorAll('[href="#' + this.elemId + '"]');

    // get the language data attribute
    this.language = JSON.parse(this.elem.getAttribute('data-language'));

    // remove the element from the dom
    this.elem.parentNode.removeChild(this.elem);
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {

    // is there already a modal container?
    this.modalContainer = document.querySelector('.' + this.classContainer);

    // if not, create one
    if (!this.modalContainer) {
      this.addModal();

    // otherwise save the variables
    } else {
      this.modalClose = this.modalContainer.querySelector('.' + this.classClose);
      this.modalCloseAlt = this.modalContainer.querySelector('.' + this.classCloseAlt);
      this.modalContent = this.modalContainer.querySelector('.' + this.classContent);
    }
  },

  addModal: function() {

    // outer container/ui block
    this.modalContainer = document.createElement('div');
    this.modalContainer.classList.add(this.classContainer);

    // close button
    this.modalClose = document.createElement('button');
    this.modalClose.classList.add(this.classClose);
    this.modalClose.textContent = this.language.close;

    // alternate close links
    this.modalCloseAltP = document.createElement('p');
    this.modalCloseAltP.classList.add(this.classCloseAlt);
    this.modalCloseAltP.classList.add('u-text-centered');

    this.modalCloseAlt = document.createElement('button');
    this.modalCloseAlt.textContent = this.language.close;

    this.modalCloseAltP.appendChild(this.modalCloseAlt);

    // content container
    this.modalContent = document.createElement('div');
    this.modalContent.classList.add(this.classContent);
    this.modalContent.setAttribute('tabindex', '-1');

    this.modalContainer.appendChild(this.modalClose);
    this.modalContainer.appendChild(this.modalContent);
    this.modalContainer.appendChild(this.modalCloseAltP);

    document.body.appendChild(this.modalContainer);
  },

  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {

    // named event handlers for binding/unbinding
    this.onCloseHandler = this.closeModal.bind(this);
    this.onFocusRestrict = this.a11yFocus.bind(this);

    for (var i = 0, len = this.triggers.length; i < len; i++) {
      this.triggers[i].addEventListener('click', this.openModal.bind(this));
      this.triggers[i].addEventListener('mouseenter', this.hoverTriggers.bind(this));
      this.triggers[i].addEventListener('mouseleave', this.hoverTriggers.bind(this));
      this.triggers[i].addEventListener('focus', this.hoverTriggers.bind(this));
      this.triggers[i].addEventListener('blur', this.hoverTriggers.bind(this));
    }
  },

  hoverTriggers: function() {
    for (var i = 0, len = this.triggers.length; i < len; i++) {
      this.triggers[i].classList.toggle('-hover');
    }
  },

  a11yFocus: function(event) {
    Util.focusRestrict(event, this.modalContainer, this.isOpen);
  },

  toggleEventListners: function(action) {
    this.modalClose[action]('click', this.onCloseHandler);
    this.modalCloseAlt[action]('click', this.onCloseHandler);
    document[action]('keydown', this.onCloseHandler);
    window[action]('keydown', this.onFocusRestrict);
  },

  openModal: function(event) {

    // fix page scrolling and save reference to current scroll position
    this.scrollPos = Util.scrollY();
    document.documentElement.setAttribute('data-scroll', 'false');
    document.body.style.top = '-' + this.scrollPos + 'px';

    // save reference to link that was clicked to open modal
    this.opener = document.activeElement;

    // hide all content that's not inside the a11y content wrapper from screen readers
    this.contentWrapper.setAttribute('aria-hidden', 'true');
    this.isOpen = true;

    // add content to modal and set open class
    this.modalContent.innerHTML = this.elem.innerHTML;
    this.modalContainer.classList.add('-modal-open');

    // trigger any module features
    if (this.features.length) {
      var Module = require('../modules/' + this.features[0]);
      new Module(this.modalContent.firstElementChild);
    }

    // bind event listeners on modal
    this.toggleEventListners('addEventListener');

    // safely re-inits element queries on modal content
    // without doubling queries on already-attached elements
    ElementQueries.init();

    // send focus to modal container
    this.modalContent.focus();

    event.preventDefault();
  },

  closeModal: function(event) {
    if (this.isOpen && (!event.keyCode || event.keyCode === 27)) {

      // send focus back to opener
      if (whatInput.ask() === 'keyboard') this.opener.focus();

      // make content visible to screen readers
      this.contentWrapper.removeAttribute('aria-hidden');
      this.isOpen = false;

      // remove open class and empty modal container
      this.modalContainer.classList.remove('-modal-open');

      // setTimeout to give exit animation time to run
      window.setTimeout(function() {
        this.modalContent.innerHTML = '';
      }.bind(this), 500);

      // un-bind all event listeners
      this.toggleEventListners('removeEventListener');

      // reset scroll position and remove scroll restriction
      document.documentElement.removeAttribute('data-scroll');
      document.body.style.top = 'auto';
      window.scroll(0, this.scrollPos);

      event.preventDefault();
    }
  }

};

module.exports = Modal;
