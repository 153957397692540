var Util = require('../utilities');
var Odometer = require('../vendor/odometer');

window.odometerObj = window.odometerObj || {};

TextStat = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

TextStat.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp']);
  },

  animated: false,

  setVariables: function() {
    this.elementGuid = Util.guid();
    window.odometerObj[this.elementGuid] = {};

    this.offsetAttr = this.elem.getAttribute('data-offset');
    this.offset = (this.offsetAttr) ? this.offsetAttr : '60%';
    this.stats = this.elem.querySelectorAll('[data-stat]');
    this.numStats = this.stats.length;
  },

  setUp: function() {

    // add elementGuid to the parent container as a data attribute
    this.elem.setAttribute('data-stat-guid', this.elementGuid);

    // loop over stats and set up odometer
    for (var i = 0, len = this.stats.length; i < len; i++) {
      var statGuid = Util.guid();
      var statNum = this.stats[i].querySelector('[data-stat-number]');

      statNum.setAttribute('data-number', statNum.textContent);
      statNum.setAttribute('data-guid', statGuid);

      // add new odometer object to a nested object for referencing when
      // waypoint triggers the animation
      window.odometerObj[this.elementGuid][statGuid] = new Odometer({
        el: statNum,
        duration: 3000,
        value: 0,
        theme: 'minimal'
      });
    }

    // add waypoint trigger
    new Waypoint({
      element: this.elem,
      handler: this.animate.bind(this),
      offset: this.offset
    });
  },

  animate: function() {

    // only do this work if the animation hasn't been triggered
    if (!this.animated) {

      // set a main attribute to trigger the fade-in animation
      this.elem.setAttribute('data-waypoint', 'visible');

      // loop over the odometer objects related to this element's guid
      for (var odometer in window.odometerObj[this.elementGuid]) {

        // get the individual stat element by guid
        var statByGuid = this.elem.querySelector('[data-guid="' + odometer + '"]');

        // trigger the odometer
        window.odometerObj[this.elementGuid][odometer].update(statByGuid.getAttribute('data-number'));
      }

      this.animated = true;
    }
  }

};

module.exports = TextStat;
