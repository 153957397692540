var Util = require('../utilities');
var whatInput = require('what-input');

Collapsible = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

Collapsible.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  setVariables: function() {
    if (this.features.includes('open')) {
      this.collapsed = false;
    } else {
      this.collapsed = true;
    }

    this.language = JSON.parse(this.elem.getAttribute('data-language'));
    this.collapsibleRegion = document.getElementById(this.elem.getAttribute('aria-controls'));
    this.collapsibleContent = this.collapsibleRegion.querySelector('[data-collapsible-content]');

    this.setHeight();
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {
    this.collapsibleRegion.setAttribute('data-collapsible-target', '');
    this.collapsibleRegion.setAttribute('tabindex', '-1');

    this.baseToggle();
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.elem.addEventListener('click', this.handleClick.bind(this));

    window.addEventListener('resize', Util.debounce(this.setHeight.bind(this), 500));
    window.pubSub.subscribe('fontsLoaded', this.setHeight.bind(this));
  },

  setHeight: function() {
    if (this.collapsibleContent) this.targetHeight = this.collapsibleContent.offsetHeight;
    this.collapsibleRegion.style.height = (!this.collapsed) ? this.targetHeight + 'px' : '0';
  },

  handleClick: function(event) {
    this.collapsed = !this.collapsed;
    this.baseToggle();
    this.clickToggle();

    event.preventDefault();
  },

  baseToggle: function() {
    this.elem.setAttribute('aria-expanded', !this.collapsed);
    this.collapsibleRegion[!this.collapsed ? 'removeAttribute' : 'setAttribute']('aria-hidden', this.collapsed);

    this.setHeight();
  },

  clickToggle: function() {
    this.elem.innerHTML = this.language[(this.collapsed) ? 'open' : 'close'];
  }

};

module.exports = Collapsible;
