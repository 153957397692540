/*jshint multistr: true */
/*
  --------------------
  Search

  Powers the rotary-federated-solr-client search form
  --------------------
*/

(function($, jwPaginate, dateFormat){
  // Replace localization strings in date format library
  dateFormat.i18n.dayNames = [
    Drupal.t("Sun"),
    Drupal.t("Mon"),
    Drupal.t("Tue"),
    Drupal.t("Wed"),
    Drupal.t("Thu"),
    Drupal.t("Fri"),
    Drupal.t("Sat"),
    Drupal.t("Sunday"),
    Drupal.t("Monday"),
    Drupal.t("Tuesday"),
    Drupal.t("Wednesday"),
    Drupal.t("Thursday"),
    Drupal.t("Friday"),
    Drupal.t("Saturday"),
  ];

  dateFormat.i18n.monthNames = [
    Drupal.t("Jan"),
    Drupal.t("Feb"),
    Drupal.t("Mar"),
    Drupal.t("Apr"),
    Drupal.t("May"),
    Drupal.t("Jun"),
    Drupal.t("Jul"),
    Drupal.t("Aug"),
    Drupal.t("Sep"),
    Drupal.t("Oct"),
    Drupal.t("Nov"),
    Drupal.t("Dec"),
    Drupal.t("January", {}, {context: 'Long month name'}),
    Drupal.t("February", {}, {context: 'Long month name'}),
    Drupal.t("March", {}, {context: 'Long month name'}),
    Drupal.t("April", {}, {context: 'Long month name'}),
    Drupal.t("May", {}, {context: 'Long month name'}),
    Drupal.t("June", {}, {context: 'Long month name'}),
    Drupal.t("July", {}, {context: 'Long month name'}),
    Drupal.t("August", {}, {context: 'Long month name'}),
    Drupal.t("September", {}, {context: 'Long month name'}),
    Drupal.t("October", {}, {context: 'Long month name'}),
    Drupal.t("November", {}, {context: 'Long month name'}),
    Drupal.t("December", {}, {context: 'Long month name'})
  ];
  var FacetHelper = {
    // The facetLabels object provides mappings between the machine names for facet options, and the plaintext label shown to the user.
    facets: {
      content_type: [
        {
          value: "content_page",
          regex: /^content_page$/i,
          label: Drupal.t("Content Page", {}, {context: 'Search Facet Label: content_type'})
        },
        {
          value: "document",
          regex: /^document$/i,
          label: Drupal.t("Document", {}, {context: 'Search Facet Label: content_type'})
        },
        {
          value: "management_tool",
          regex: /^management_tool$/i,
          label: Drupal.t("Management Tool", {}, {context: 'Search Facet Label: content_type'})
        }
      ],
      document_mimetype: [
        {
          value: "application/pdf",
          regex: /^application\/pdf$/i,
          label: Drupal.t("PDF", {}, {context: 'Search Facet Label: document_mimetype'})
        },
        {
          value: "application/vnd.openxmlformats-officedocument.presentationml.presentation || application/vnd.ms-powerpoint",
          regex: /^application\/vnd\.openxmlformats-officedocument\.presentationml\.presentation|application\/vnd\.ms-powerpoint$/i,
          label: Drupal.t("Presentation", {}, {context: 'Search Facet Label: document_mimetype'})
        },
        {
          value: "text/* || application/msword || application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          regex: /^(text\/.+)|application\/msword|application\/vnd\.openxmlformats-officedocument\.wordprocessingml\.document$/i,
          label: Drupal.t("Text", {}, {context: 'Search Facet Label: document_mimetype'})
        },
        {
          value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || application/vnd.ms-excel",
          regex: /^application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet|application\/vnd\.ms-excel/i,
          label: Drupal.t("Table", {}, {context: 'Search Facet Label: document_mimetype'})
        },
        {
          value: "image/*",
          regex: /^image\/.+$/i,
          label: Drupal.t("Image", {}, {context: 'Search Facet Label: document_mimetype'})
        },
        {
          value: "video/*",
          regex: /^video\/.+$/i,
          label: Drupal.t("Video", {}, {context: 'Search Facet Label: document_mimetype'})
        },
        {
          value: "!(application/pdf || '' || (text/* || application/msword || application/vnd.openxmlformats-officedocument.wordprocessingml.document || application/vnd.openxmlformats-officedocument.presentationml.presentation) || (application/vnd.openxmlformats-officedocument.spreadsheetml.sheet || application/vnd.ms-excel) || image/* || video/*)",
          regex: /^.*$/,
          label: Drupal.t("Various", {}, {context: 'Search Facet Label: document_mimetype'})
        }
      ],
      //These are all pre-localized for use across all locales, no need for drupal translation
      langcode: [
        {
          value: "de",
          regex: /de/i,
          label: "Deutsch"
        },
        {
          value: "en",
          regex: /en/i,
          label: "English"
        },
        {
          value: "es",
          regex: /es/i,
          label: "Español"
        },
        {
          value: "fr",
          regex: /fr/i,
          label: "Français",
        },
        {
          value: "it",
          regex: /it/i,
          label: "Italiano"
        },
        {
          value: "ja",
          regex: /ja/i,
          label: "日本語"
        },
        {
          value: "ko",
          regex: /ki/i,
          label: "한국어"
        },
        {
          value: "pt || pt-br",
          regex: /pt|pt-br/i,
          label: "Português"
        }
      ]
    },
    /**
     * Take the facet data from a search result's facet_counts.facet_fields and process it into a set of facet options for use in the front end.
     */
    getFacetFields: function (facetFieldsFromSOLR) {
      var parent = this;
      var results = [];

      var facetsCopy = $.extend(true, {}, this.facets);

      for (var facetName in facetsCopy) {
        var facetDataFromSOLR = facetFieldsFromSOLR[facetName];

        // First pass - convert the facet fields from SOLR into a usable format
        var facetOptions = [];
        var facetOptionsRaw = [];
        for (var i=0;i<facetDataFromSOLR.length;i+=2) {
          var name = facetDataFromSOLR[i];
          var count = facetDataFromSOLR[i+1];

          facetOptionsRaw.push({
            name: name,
            count: count,
            claimed: false //A flag of whether this facet's items have already been 'claimed'
          });
        }

        //Second pass - iterate through the facets to assign solr data to our facets
        for (var j = 0; j < facetsCopy[facetName].length; j++) {
          var facetOption = facetsCopy[facetName][j];

          if (typeof facetOption.count === 'undefined') {
            facetOption.count = 0;
          }

          for (var jj = 0; jj < facetOptionsRaw.length; jj++) {
            var facetOptionRaw = facetOptionsRaw[jj];
            if (facetOptionRaw.claimed) {
              continue;
            }
            if (facetOption.regex.test(facetOptionRaw.name)) {
              facetOption.count += facetOptionRaw.count;
              facetOptionRaw.claimed = true;
            }
          }

          facetOptions.push(facetOption);

        }

        results.push({
          name: facetName,
          options: facetOptions
        });


      }

      return results;
    },
    getFacetLabel: function (name, key) {
      if (typeof this.facets[name] !== 'undefined') {
        var found = this.facets[name].find(function(item){return item.value == key;});
        if (found) {
          return found.label;
        }
      }
      return null;
    }
  }
  var Analytics = {
    dataLayerName: 'rotaryDDO',
    dataLayerExists: function () {
      return (
        typeof window !== 'undefined' &&
        typeof window[this.dataLayerName] !== 'undefined' &&
        typeof window._satellite !== 'undefined'
      );
    },
    fireTrackingEvent: function (eventName, payload) {
      if (!this.dataLayerExists()) {
        return;
      }

      // Don't try and track if we don't have the Launch plugin or we are have a pageview exclusion.
      if (
        typeof window._satellite !== 'undefined'
      ) {
        Object.keys(payload).map(function (key) {
          // Property-specific formatting
          switch (key) {
            case 'searchFilters':
              payload[key] = JSON.stringify(payload[key]);
              break;
            default:
              break;
          }
        });

        window._satellite.track(eventName, payload);
      }

    }
  };

  var SearchHelpers = {
    unescapeReservedCharacters: function (str, reservedCharacters) {      
      /**
       * Escape function for regex characters
       * @see https://stackoverflow.com/a/3561711 
       */
      function escapeRegex(string) {
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
      }

      // str = str.replace(/\\\\/g,'\\');
      var regex = new RegExp('\\\\([' + escapeRegex(reservedCharacters)+'])', 'ig');
      return str.replace(regex, '$1');
    },
    trimStringToLength: function (inputString, length) {
      return (inputString.length > length) ? (inputString.substring(0, length) + '...') : inputString;
    },

    /**
     * Convert a raw byte integer to a human readable format
     * @param  {integer} bytes
     * @return {string}
     */
    bytesToSize: function (bytes) {
      if (bytes == 0) return Drupal.t('0 byte');
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
      var fileSize = Math.round(bytes / Math.pow(1000, i), 2);
      switch (i) {
        case 0:
          return Drupal.t('@size bytes', {'@size': fileSize});
        case 1:
          return Drupal.t('@size KB', {'@size': fileSize});
        case 2:
          return Drupal.t('@size MB', {'@size': fileSize});
        case 3:
          return Drupal.t('@size GB', {'@size': fileSize});
        case 4:
          return Drupal.t('@size TB', {'@size': fileSize});
      }
    },
  };

  Search = function(elem) {
    this.elem = elem;
    this.pageLanguage = $('html').attr('lang');
    this.config = {
      solr_proxy_endpoint: '/' + this.pageLanguage + '/rotary_federated_solr_client/search',
      my_rotary_cms_base_url: Drupal.settings.rotary_federated_solr_client.my_rotary_cms_base_url,
      my_rotary_base_url: Drupal.settings.rotary_federated_solr_client.my_rotary_base_url,
      lucene_reserved_characters: Drupal.settings.rotary_federated_solr_client.lucene_reserved_characters,
    };
    this.init();
  };

  Search.prototype = {
    pageLanguage: null,
    debugEnabled: false,
    debug: function () {
      if (this.debugEnabled) {
        console.log(arguments);
      }
    },
    /** Search application state */
    state: {},

    /** Default state application state */
    getDefaultState: function () {
      return {
        loading: false,
        initialized: false,
        currentPage: 1,
        resultsPerPage: 10,
        orderBy: 'relevance',
        query : "",
        currentSearchResult: null,
        facets: {
          langcode: this.pageLanguage,
          content_type: '',
          document_mimetype: ''
        },    
      };
    },

    /** Configuration properties */
    config: {
      solr_proxy_endpoint: null
    },

    mappings: {
      sourceIdToDisplaySite: {
        'rotary': 'https://www.rotary.org',
        'rotary_international_convention': 'https://convention.rotary.org',
        'my_rotary_legacy_app': 'https://my.rotary.org',
        'my_rotary_web_app': 'https://my.rotary.org',
        'end_polio': 'https://www.endpolio.org'
      }
    },

    helpers: {

    },

    /**
     * Initialize the state
     */
    initializeState: function () {
      this.state = this.getDefaultState();
    },

    /**
     * Initialize the application. Should be called at bottom of module code.
     */
    init: function () {
      var parent = this;
      this.initializeState();

      // Push query into state on first page load
      if (
        (typeof Drupal.settings.rotary_federated_solr_client.search_query !== "undefined") &&
        (Drupal.settings.rotary_federated_solr_client.search_query)
      ) {
        this.state.query = Drupal.settings.rotary_federated_solr_client.search_query;
      }

      // Handle search form submit event (includes click on button)
      this.elements().$form.on('submit', function (event) {
        event.preventDefault();
        // Perform search
        parent.performSearch({resetPage: true, resetOrderBy: true, resetResultsPerPage: true});
      });

      // Handle clicks on reset button
      this.elements().$resetButton.on('click', function (event) {
        // If we have a default search query, allow the reset button to reload the current page using its href attribute
        if (
          (typeof Drupal.settings.rotary_federated_solr_client.search_query !== "undefined") &&
          (Drupal.settings.rotary_federated_solr_client.search_query)
        ) {
          //Do nothing
        }
        else {
          event.preventDefault();
          parent.resetSearch();
        }
      });
      //handle clicks on result per page dropdown
      var $resultsPerPage=this.elements().$resultsPerPageSelect;

      $resultsPerPage.unbind('change');
      
      $resultsPerPage.bind('change', function () {
        parent.state.currentPage = 1;
        parent.performSearch();
      });
  
      //handle clicks on order by dropdown
      var $resultsOrderby=this.elements().$resultsOrderbySelect;

      $resultsOrderby.unbind('change');
      
      $resultsOrderby.bind('change', function () {
        parent.state.currentPage = 1;
        parent.performSearch();
      });

      this.state.initialized = true;

      this.writeStateToDOM();

      // If initial state has a query, run the search
      if (this.state.query) {
        this.performSearch();
      }
      this.debug('Search initialized');
    },

    /**
     * Reset the search page to its initial state
     */
    resetSearch: function () {
      this.debug('reset');
      this.initializeState();
      this.writeStateToDOM();
      this.elements().$query.focus();
    },

    /**
     * Go to the specified page of results for the current query
     * @param  {int} pageNumber The page to go to. 1-indexed
     */
    gotoPageNumber: function (pageNumber) {
      this.debug('gotoPageNumber: ', pageNumber);
      this.state.currentPage = pageNumber;
      this.performSearch();
    },

    /**
     * Go to the first page of results for the current query
     */
    gotoPageFirst: function () {
      this.gotoPageNumber(1);
    },

    /**
     * Go to the Previous page of results for the current query
     */
    gotoPagePrev: function () {
      this.gotoPageNumber(this.state.currentPage - 1);
    },

    /**
     * Go to the next page of results for the current query
     */
    gotoPageNext: function () {
      this.gotoPageNumber(this.state.currentPage + 1);
    },

    /**
     * Go to the last page of results for the current query
     */
    gotoPageLast: function () {
      this.gotoPageNumber(this.state.pagination.totalPages);
    },

    /**
     * Read form values into state object
     */
    readStateFromDOM: function () {

      this.state.query = this.elements().$query.val().trim();

      if (this.state.currentSearchResult) {
        for (var facetName in this.elements().$facetSelects) {
          var $facetSelect = this.elements().$facetSelects[facetName];
          var value = $facetSelect.val();
          if (typeof this.state.facets[facetName] !== 'undefined') {
            this.state.facets[facetName] = value;
          }
        }

        // Reset facet document_mimetype to null if content_type is not document
        if (this.state.facets.content_type !== 'document') {
          this.state.facets.document_mimetype = null;
        }
      }
      this.state.resultsPerPage=this.elements().$resultsPerPageSelect.val();

      this.state.orderBy=this.elements().$resultsOrderbySelect.val();
     
      this.debug('Updated state from form: ', this.state);
    },

    /**
     * Write the current state back out to the DOM, including search results
     */
    writeStateToDOM: function () {
      var parent = this;

      // Remove the old results from the list
      this.elements().$results.empty();

      // Clear the pagination
      this.elements().$pagination.empty();

      // Update form elements
      this.elements().$query.val(this.state.query);

      for (var facetNameForEmpty in this.elements().$facetSelects) {
        var $facetSelectForEmpty = this.elements().$facetSelects[facetNameForEmpty];
        $facetSelectForEmpty.empty();
      }

      //////////////////////////////
      /// Apply classes to the root element
      //////////////////////////////
      if (this.state.loading) {
        this.elements().$root.addClass('loading');
      }
      else {
        this.elements().$root.removeClass('loading');
      }

      if (this.state.initialized) {
        this.elements().$root.addClass('initialized');
      }
      else {
        this.elements().$root.removeClass('initialized');
      }

      if (this.state.currentSearchResult) {
        this.elements().$root.addClass('has-search-active');
      }
      else {
        this.elements().$root.removeClass('has-search-active');
      }

      // EXIT WRITING FUNCTION IF NO SEARCH RESULTS PRESENT
      if (!this.state.currentSearchResult) {
        return;
      }

      //////////////////////////////
      /// Build Search Results
      //////////////////////////////
      this.state.currentSearchResult.response.docs.forEach(function(result, i) {

        var isCdaLink = (typeof result.bundle !== 'undefined') && (result.bundle == 'cda_link');

        var searchRank = parent.state.currentSearchResult.response.start + i;

        // Create URL
        var url = '';

        // if source_path begins with http://, it is the full url and we use it directly
        if ((typeof result.source_path !== 'undefined') && result.source_path) {
          if (result.source_path.indexOf('http') === 0) {
            url = result.source_path;
          }
          // otherwise we need to prepend the site (protocol+domain+endpoint) to the source path
          else {
            // Trim trailing slash on 'site' and then stick on the path
            url = result.site.replace(/\/+$/,'') + '/' + result.source_path;
          }
        }

        //////////////////////////////
        /// Post-process URL
        //////////////////////////////

        //Remove embed=true querystring (present on some my-cms urls)
        url = url.replace('?embed=true', ''); 


        //For non-document results, replace my-cms*.rotary.org with my*.rotary.org (bypasses a redirect and makes urls 'canonical')
        if (result.content_type !== 'document') {
          url = url.replace('//my-cms','//my'); 
        }

        //////////////////////////////
        /// Create elements
        //////////////////////////////

        // Create list element
        var $li = $('<li>');

        function trackClick() {
          Analytics.fireTrackingEvent('searchResultClicked', {
            searchResultDestination: url,
            searchType: 'Site Search',
            searchResultIndex: searchRank,
          });
        }

        // Create title
        var $titleWrapper;

        if (url) {
          $titleWrapper = $('<a>').attr({href: url}).on('click', trackClick);
        }
        else{
          $titleWrapper = $('<span>');
        }

        $li.append($titleWrapper);

        var $title = $('<h3>').html(result.title_fulltext).appendTo($titleWrapper);

        if (url) {
          var displayUrl = (url.length <= 50) ? url : SearchHelpers.trimStringToLength(url, 47);
          var $url = $('<a>').attr({class:'link', href: url}).text(displayUrl).on('click', trackClick).appendTo($li);
          $li.append($url);
        }

        // Get highlight
        var highlight;

        var highlightRaw = parent.state.currentSearchResult.highlighting[result.id];
        if (
          (typeof highlightRaw.content_fulltext !== 'undefined') &&
          (typeof highlightRaw.content_fulltext[0] !== 'undefined')
        ) {
          highlight = highlightRaw.content_fulltext[0];
        }

        // Create summary
        var summaryText = highlight ? highlight : SearchHelpers.trimStringToLength(result.content_fulltext[0], 234);

        var $summary = $('<p>').html(summaryText);
        $li.append($summary);

        // Add CDA links to summary
        if (isCdaLink) {

          $.ajax({
            method: 'GET',
            url: parent.config.my_rotary_cms_base_url + '/' + parent.pageLanguage + '/restapi/cda/links/' + result.item_id,
            dataType: 'json'
          })
            .done(function(data) {
              parent.debug('CDA link data success', data);

              if (data.length) {
                var $cdaLinksList = $('<ul/>').addClass('cda-links');

                data.forEach(function(cdaLinkData, i) {
                  var $cdaLinkLI= $('<li/>');
                  var $cdaLink = $('<a/>').attr({href: parent.config.my_rotary_base_url + cdaLinkData.path}).html(cdaLinkData.title);
                  $cdaLink.appendTo($cdaLinkLI);
                  $cdaLinkLI.appendTo($cdaLinksList);
                });
                $cdaLinksList.appendTo($summary);
              }
            })
            .fail(function(error) {
              parent.debug('CDA link data fail', error);
            });
        }

        // Build metadata string
        var metaDataComponents = [];

        // Metadata:`` Update date
        var updatedDate = new Date(result.date_modified);
        var displayDate = dateFormat(updatedDate, "d mmm yyyy");
        if (displayDate) {
          // Grab the translated string for e.g. 'Updated 1 Jan 2020'
          var displayDateText = Drupal.t('Updated @updated-date', {'@updated-date': displayDate}, {context: 'Search Results'});

          metaDataComponents.push(displayDateText);
        }

        // Metadata: Document Size
        if (typeof result.document_file_size !== 'undefined') {
          var displayFileSize = Drupal.t('Size @file-size', {'@file-size': SearchHelpers.bytesToSize(result.document_file_size)}, {context: 'Search Results'});
          metaDataComponents.push(displayFileSize);
        }

        // Metadata: Site
        if (result.source_id) {
          var displaySite;
          // Map source ID to a friendly display version e.g. my_rotary_legacy_app' => 'https://my.rotary.org',
          if (typeof parent.mappings.sourceIdToDisplaySite[result.source_id] !== 'undefined')   {
            displaySite = parent.mappings.sourceIdToDisplaySite[result.source_id];
          }
          else {
            displaySite = result.source_id;
          }

          metaDataComponents.push(displaySite);
        }

        var $meta = $('<div><p class="metadata">' + metaDataComponents.join(' | ')+ '</p></div>');
        $li.append($meta);

        // Append to results
        parent.elements().$results.append($li);
      });

      // Update the results count text
      this.elements().$resultsCountOnPage.text(this.state.currentSearchResult.response.docs.length);
      this.elements().$resultsCountTotal.text(this.state.currentSearchResult.response.numFound);
      this.elements().$resultsCountQuery.text(SearchHelpers.unescapeReservedCharacters(this.state.currentSearchResult.responseHeader.params.q, this.config.lucene_reserved_characters));

      //////////////////////////////
      /// Facets: Prep facets data
      //////////////////////////////
      var facetFields = FacetHelper.getFacetFields(this.state.currentSearchResult.facet_counts.facet_fields);

      for (var i = 0; i < facetFields.length; i++) {
        var facetField = facetFields[i];

        $facetSelect = parent.elements().$facetSelects[facetField.name];

        // Check if this facet even exists in the DOM, skip if not
        if (!$facetSelect.length) {
          return; //continue
        }

        //////////////////////////////
        /// Facets: Build select and options elements
        //////////////////////////////
        //remove original options
        $facetSelect.empty();

        // Add 'Any' option as first option
        var $anyOption = $('<option>')
          .val('')
          .html(Drupal.t('Any', {}, {context: 'Search Form'}));

        $anyOption.appendTo($facetSelect);

        // Add options for each element in facetDataArray
        for (var ii = 0; ii < facetField.options.length; ii++) {
          var facetOption = facetField.options[ii];

          var $option = $('<option>')
            .val(facetOption.value)
            .html(facetOption.label + ' (' + facetOption.count + ')')
            .appendTo($facetSelect);
          // Check if this is the currently selected facet in the state
          if (parent.state.facets[facetField.name] === facetOption.value) {
            $option.attr({'selected': 'selected'});
          }
          else if (facetOption.count == 0) {
            $option.attr({disabled: 'disabled'});
            $option.appendTo($facetSelect);
          }
        }
        
        $facetSelect.unbind('change');
        $facetSelect.bind('change', function () {
          parent.state.currentPage = 1;
          parent.performSearch();
        });
        parent.debug('Loaded facet element with options', facetField);

      }



      // Toggle display of 'File Type' facet based on value of 'Content Type' facet
      var $contentTypeFacet = this.elements().$facetSelects.content_type;
      var $documentMimetypeFacet = this.elements().$facetSelects.document_mimetype;
      var $documentMimetypeFacetContainer = $documentMimetypeFacet.closest('.facet-container');

      if ($contentTypeFacet.val() === 'document') {
        $documentMimetypeFacetContainer.show();
      }
      else {
        $documentMimetypeFacetContainer.hide();
      }
     /////update result per page select
     this.elements().$resultsPerPageSelect.val(this.state.resultsPerPage);


    /////update orderby select
    this.elements().$resultsOrderbySelect.val(this.state.orderBy);


      //////////////////////////////
      /// Build Pagination
      //////////////////////////////
      this.debug('this.state.pagination', this.state.pagination);

      // Locally-scoped function to create pagination items
      function _createPaginationItem (label, clickCallback) {
        // Create list item
        var $li = $('<li>');

        // Create link
        var $a = $('<a>')
          .html(label)
          .attr('href', '#')
          .on('click', function(event) {
            event.preventDefault();
            clickCallback();
          });

        $li.append($a);

        return $li;
      }


      // Create numbered pagination items
      this.state.pagination.pages.forEach(function (pageNumber, i) {
        var $paginationItem = _createPaginationItem(pageNumber, function () {
          parent.gotoPageNumber(pageNumber);
        });

        if (parent.state.pagination.currentPage === pageNumber) {
          $paginationItem.addClass('current');
        }

        parent.elements().$pagination.append($paginationItem);
      });

      if (this.state.pagination.currentPage > 1) {
        // Create 'Previous' pagination item
        var $prevPaginationItem = _createPaginationItem('<', function() {parent.gotoPagePrev();});
        this.elements().$pagination.prepend($prevPaginationItem);

        // Create 'First' pagination item
        var $firstPaginationItem = _createPaginationItem('<<', function() {parent.gotoPageFirst();});
        this.elements().$pagination.prepend($firstPaginationItem);
      }

      if (this.state.pagination.currentPage < this.state.pagination.totalPages) {
        // Create 'Next' pagination item
        var $nextPaginationItem = _createPaginationItem('>', function() {parent.gotoPageNext();});
        this.elements().$pagination.append($nextPaginationItem);

        // Create 'Last' pagination item
        var $lastPaginationItem = _createPaginationItem('>>', function() {parent.gotoPageLast();});
        this.elements().$pagination.append($lastPaginationItem);
      }
    },

    throwSearchError: function (errorMsg) {
      alert(errorMsg);
    },

    /**
     * Perform the search operation using the current form state
     */
    performSearch: function (options) {
      var parent = this;
      this.readStateFromDOM();
      var defaults = {
        resetPage: false,
        resetOrderBy: false,
        resetResultsPerPage: false
      }
      var searchSettings = $.extend({}, defaults, options);
      //search reset conditions
      if (searchSettings.resetPage) {
        parent.state.currentPage = 1;
      }
      if (searchSettings.resetOrderBy) {
        parent.state.orderBy = parent.getDefaultState().orderBy;
      }
      if (searchSettings.resetResultsPerPage) {
        parent.state.resultsPerPage = parent.getDefaultState().resultsPerPage;
      }
      // Do not execute search on empty query
      if (!this.state.query) {
        this.elements().$query.focus();
        return;
      }

      this.state.loading = true;
      this.writeStateToDOM();

      // Convert page to 0-indexed before sending to SOLR
      var pageZeroIndexed = (this.state.currentPage - 1);
      if (pageZeroIndexed < 0) {
        pageZeroIndexed = 0;
      }

      $.ajax({
        method: 'GET',
        url: this.config.solr_proxy_endpoint,
        dataType: 'json',
        data: {
          page: pageZeroIndexed,
          query: this.state.query,
          facets: this.state.facets,
          resultsPerPage: this.state.resultsPerPage,
          orderBy: this.state.orderBy,
        }
      })
        .done(function (data) {
          parent.debug('Search success', data);

          parent.state.currentSearchResult = data;

          // Sometimes when SOLR fails it will return an empty array, this represents an error state
          if (Array.isArray(data) && (data.length == 0)) {
            parent.debug('Search error: Empty result');

            parent.resetSearch();
            var errorMsg = Drupal.t("There was an error with your search. Please try again.", {}, {context: 'Search error'});
            parent.throwSearchError(errorMsg);
            return;
          }

          parent.state.pagination = jwPaginate(
            data.response.numFound,           //Num results
            parent.state.currentPage,                //Current page (index starts at 1)
            data.responseHeader.params.rows,  //Results per page
            5                                 //Max number of pages to show in pagination component
          );

          if (data.response.numFound > 0) {
            Analytics.fireTrackingEvent('searchResults', {
              searchTerm: parent.state.query,
              searchTotalResults: data.response.numFound,
              searchType: 'Site Search',
              searchLanguage: parent.state.facets.langcode,
              searchResultsPerPage: parent.state.resultsPerPage,
              searchOrderBy: parent.state.orderBy,
              searchFilters: {
                keywords: parent.state.query,
                content_type: parent.state.facets.content_type,
                document_mimetype: parent.state.facets.document_mimetype,
                langcode: parent.state.facets.langcode,
                topic:""
              },
            });
          }
          else {
            Analytics.fireTrackingEvent('searchResultsEmpty', {
              searchTerm: parent.state.query,
              searchType: 'Site Search',
              searchLanguage: parent.state.facets.langcode,
              searchResultsPerPage: parent.state.resultsPerPage,
              searchOrderBy: parent.state.orderBy,
              searchFilters: {
                keywords: parent.state.query,
                content_type: parent.state.facets.content_type,
                document_mimetype: parent.state.facets.document_mimetype,
                langcode: parent.state.facets.langcode,
                topic:""
              },
            });
          }

          parent.state.loading = false;
          parent.writeStateToDOM();
        })
        .fail(function (error) {
          parent.debug('Search error: AJAX error', error);

          parent.resetSearch();
          var errorMsg = Drupal.t("There was an error with your search. Please try again.", {}, {context: 'Search error'});
          parent.throwSearchError(errorMsg);
          return;
        });
    },

    /** Element cache for elements method */
    cachedElements: {},

    /**
     * Get all of our jQuery elements in one place
     * @param  {boolean} force Whether to force a refresh of the cache
     * @return {Object} Object literal with jquery element objects on each property
     */
    elements: function (force) {
      if (typeof force === 'undefined') {
        force = false;
      }

      if (Object.getOwnPropertyNames(this.cachedElements).length && !force) {
        return this.cachedElements;
      }

      var $root = $(this.elem);

      this.cachedElements = {
        $root: $root,
        $form: $root.find('form'),
        $submitButton: $('.submit-button', $root),
        $resetButton: $('.reset-button', $root),
        $query: $('input[name="query"]', $root),
        $results: $('ul.search-results', $root),
        $resultsCount: $('.results-count', $root),
        $resultsPerPageSelect: $('.results-per-page-select', $root),
        $resultsOrderbySelect: $('.results-orderby-select', $root),
        $resultsCountOnPage: $('.results-count .on-page', $root),
        $resultsCountTotal: $('.results-count .total', $root),
        $resultsCountQuery: $('.results-count .query', $root),
        $pagination: $('ul.pagination', $root),
        $facetSelects : {
          langcode: $('select[data-facet="langcode"]', $root),
          content_type: $('select[data-facet="content_type"]', $root),
          document_mimetype: $('select[data-facet="document_mimetype"]', $root),
        },
      };

      return this.cachedElements;
    }

  };

  module.exports = Search;
})(jQuery, require('../vendor/jw-paginate.js'), require('../vendor/dateformat.js'));


