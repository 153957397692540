var Util = require('../utilities');
var Vars = require('../global-variables');

NavToggle = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

NavToggle.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'toggleSetup', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  focusoutTimer: null,
  state: null,
  mq: window.matchMedia(Vars.mq.navigation),

  setVariables: function() {
    this.header = document.querySelector('[data-nav-header]');
    this.targetElem = this.elem.getAttribute('data-toggle');
    this.targetContainer = document.getElementById(this.elem.getAttribute('aria-controls'));

    if (this.features.includes('focusInput')) {
      this.focusTarget = this.targetContainer.querySelectorAll('input[type="text"],input[type="search"]');
    }

    if (this.features.includes('toggleInput')) {
      if (this.mq.matches) {
        this.toggles = document.querySelector('[data-toggle-search]');
        this.searchContainer = document.getElementsByClassName('site-nav-utility-search');
      }
    }

    // named event handlers for binding/unbinding
    this.onToggleClick = this.toggleClick.bind(this);
    this.onEscClose = this.escClose.bind(this);
    this.onA11yFocusin = this.a11yFocusin.bind(this);
    this.onA11yFocusout = this.a11yFocusout.bind(this);
  },


  /*
    --------------------
    Set up
    --------------------
  */

  toggleSetup: function() {
    this.elem.setAttribute('aria-expanded', 'false');
    this.toggleEvents();

    if (!this.mq.matches) {

      // only do this once, not for each toggle button
      if (!document.body.hasAttribute('data-mobile')) {
        document.body.setAttribute('data-mobile', '');
      }
    } else {

      if (this.features.includes('setToWindowHeight')) {
        this.unsetHeight();
      }

      // only do this once, not for each toggle button
      if (document.body.hasAttribute('data-mobile')) {
        document.body.removeAttribute('data-mobile');
      }
    }
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.mq.addListener(this.toggleSetup.bind(this));

    if (this.features.includes('setToWindowHeight')) {
      window.addEventListener('resize', Util.debounce(function() {
        if (this.state === 'open' && !this.mq.matches) this.setHeight();
      }.bind(this), 100));
    }
    if (this.features.includes('toggleInput')) {
      if (this.mq.matches) {
        this.toggles.addEventListener('click', this.toggleClickSearch.bind(this));
      }
    }
    window.pubSub.subscribe('closeNav', this.unsetHeight.bind(this));
  },

  toggleA11y: function(action) {

    // focusin/focusout keeps focus within menu when open
    this.targetContainer[(action === 'open') ? 'addEventListener' : 'removeEventListener']('focusin', this.onA11yFocusin);
    this.targetContainer[(action === 'open') ? 'addEventListener' : 'removeEventListener']('focusout', this.onA11yFocusout);
  },

  a11yFocusin: function(event) {
    window.clearTimeout(this.focusoutTimer);
  },

  a11yFocusout: function(event) {
    var elem = this.elem;

    this.focusoutTimer = window.setTimeout(function() {
      elem.focus();
    }, 10);
  },

  escClose: function(event) {

    // esc key closes
    if (event.which === Vars.keys.ESC) {
      this.toggleClick();
    }
  },

  toggleEsc: function() {
    if (this.state === 'open') {
      document.body.addEventListener('keyup', this.onEscClose);
    } else {
      document.body.removeEventListener('keyup', this.onEscClose);
    }
  },

  show: function() {
    this.searchContainer[0].style.cssText = 'display: block; visiblity: absolute';
    this.toggles.style.cssText = 'display: none; visiblity: hidden';
  },

  toggleEvents: function() {
    this.elem[(!this.mq.matches) ? 'addEventListener' : 'removeEventListener']('click', this.onToggleClick);
  },

  toggleClick: function() {

    // determine action based on data attribute
    // (works better for multiple toggle buttons than storing state locally)
    this.state = this.returnAction();

    // run utilities on all nav panels before opening a new one
    if (this.state === 'open') window.pubSub.publish('closeNav');
    if (this.state === 'close') window.pubSub.publish('closeSubNav');

    // toggle aria state
    this.elem.setAttribute('aria-expanded', (this.state === 'open') ? 'true' : 'false');

    // set attribute on body
    document.body.setAttribute(
      'data-mobile',
      (this.state === 'open') ? this.targetElem : ''
    );

    // bind/unbind esc key to close target element
    this.toggleEsc();

    // manage focus
    if (this.state === 'open') {
      this.toggleA11y(this.state);

      if (this.focusTarget) {
        var focusElem = (this.features.includes('focusInput')) ? this.focusTarget[0] : this.focusTarget;

        setTimeout(function() {
          focusElem.focus();
        }, 100);
      }
    } else if (this.state === 'close') {
      this.toggleA11y(this.state);
      this.elem.focus();
    }

    if (this.features.includes('setToWindowHeight')) {
      if (this.state === 'open') {
        this.setHeight();
      } else {
        this.unsetHeight();
      }
    }
  },

  toggleClickSearch: function(event) {
    if (this.features.includes('toggleInput')) {
      // Prevent default button behavior
      event.preventDefault();
      // On click show search
      this.show();
    }
  },

  setHeight: function() {
    // calculate window height minus header height
    // this is primarily a fix for iOS Safari where pre-scrolled browser chrome
    // isn't taken into account when using `vh`.
    this.targetContainer.style.height = (window.innerHeight - this.header.offsetHeight) + 'px';
  },

  unsetHeight: function() {
    this.targetContainer.style.height = null;
  },

  returnAction: function() {
    return (document.body.getAttribute('data-mobile') === this.targetElem) ? 'close' : 'open';
  }

};

module.exports = NavToggle;
