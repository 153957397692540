var Util = require('../utilities');
var Vars = require('../global-variables');

var Accordion = function(elem) {
  this.elem = elem;
  this.init();
};

Accordion.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  setVariables: function() {
    this.currentTab = 0;
    this.panels = [];
    this.contentContainers = [];
    this.containerHeights = [];
    this.tabs = this.elem.querySelectorAll('[data-accordion-tab]');

    this.loopTabs(function(i) {
      var link = this.returnId(this.tabs[i]);
      var panel = document.getElementById(link);
      var contentContainer = panel.querySelector('[data-accordion-content]');

      this.panels.push(panel);
      this.contentContainers.push(contentContainer);
      this.containerHeights.push(0);
    }.bind(this));
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {
    this.loopTabs(function(i) {
      this.setTabState(i);
      this.tabs[i].setAttribute('role', 'tab');
      this.tabs[i].setAttribute('aria-controls', this.returnId(this.tabs[i]));

      this.panels[i].setAttribute('role', 'tabpanel');
      this.measurePanel(i);
      this.setPanelState(i);
      this.setPanelHeight(i);
    }.bind(this));
  },

  updateTabs: function() {
    this.loopTabs(function(i) {
      this.setTabState(i);
      this.setPanelState(i);
      this.setPanelHeight(i);
    }.bind(this));
  },

  setTabState: function(pos) {
    this.tabs[pos].setAttribute('tabindex', (pos === this.currentTab) ? 0 : -1);

    if (pos === this.currentTab) {
      this.tabs[pos].setAttribute('aria-selected', 'true');
    } else {
      this.tabs[pos].removeAttribute('aria-selected');
    }
  },

  measurePanels: function() {
    this.loopTabs(function(i) {
      this.measurePanel(i);
    }.bind(this));
  },

  measurePanel: function(pos) {
    this.containerHeights[pos] = this.contentContainers[pos].offsetHeight;
    this.setPanelHeight(pos);
  },

  setPanelHeight: function(pos) {
    this.panels[pos].style.height = (pos === this.currentTab) ? this.containerHeights[pos] + 'px' : '0';
  },

  setPanelState: function(pos) {

    // set hidden state
    if (pos === this.currentTab) {
      this.panels[pos].removeAttribute('aria-hidden');
    } else {
      this.panels[pos].setAttribute('aria-hidden', 'true');
    }
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {

    // prevent default tab behavior when moving through sub list
    this.elem.addEventListener('keydown', this.blockArrows.bind(this));

    // trap focus within the sub list
    this.elem.addEventListener('keyup', this.manageFocus.bind(this));

    for (var i = 0, len = this.tabs.length; i < len; i++) {
      this.tabs[i].addEventListener('click', this.getNewTab.bind(this));
    }

    window.addEventListener('resize', Util.debounce(this.measurePanels.bind(this), 100));
    window.pubSub.subscribe('fontsLoaded', this.measurePanels.bind(this));
  },

  blockArrows: function(event) {

    // prevents the default behavior of the arrow keys
    // so they don't scroll the page
    if (
      event.which === Vars.keys.DOWN ||
      event.which === Vars.keys.UP
    ) event.preventDefault();
  },

  // customized version of focusRestrict to work specifically for accordion links
  manageFocus: function(event) {
    var key = event.which;
    var focusable = Array.prototype.slice.call(this.tabs);
    var listLength = this.tabs.length;
    var focused = document.activeElement;
    var focusIndex = focusable.indexOf(focused);

    // down/right/up/left cycles through links
    if (
      key === Vars.keys.DOWN ||
      key === Vars.keys.RIGHT ||
      key === Vars.keys.UP ||
      key === Vars.keys.LEFT
    ) {
      var direction = (key === Vars.keys.UP || key === Vars.keys.LEFT) ? 'up' : 'down';

      if (focusIndex < (listLength - 1) && direction === 'down') {
        this.currentTab = focusIndex + 1;
      } else if (focusIndex > 0 && direction === 'up') {
        this.currentTab = focusIndex -1;
      } else if (focusIndex === (listLength - 1) && direction === 'down') {
        this.currentTab = 0;
      } else {
        this.currentTab = listLength -1;
      }

      focusable[this.currentTab].focus();
      this.updateTabs();
    }
  },

  getNewTab: function(event) {
    this.currentTab = Util.getArrayPosition(this.tabs, event.currentTarget);
    this.updateTabs();

    event.preventDefault();
  },


  /*
    --------------------
    Utilities
    --------------------
  */

  loopTabs: function(work) {
    for (var i = 0, len = this.tabs.length; i < len; i++) work(i);
  },

  returnId: function(elem) {
    return elem.getAttribute('href').substring(1);
  }

};

module.exports = Accordion;
