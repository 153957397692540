var Util = require('../utilities');

Slideshow = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

Slideshow.prototype = {

  init: function() {
    Util.fire(this, ['addScrollTrack', 'setVariables', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  current: null,

  setVariables: function() {
    this.list = this.elem.querySelector('[data-list]');
    this.slides = this.list.querySelectorAll('[data-slide]');
    this.numSlides = this.slides.length;

    this.language = JSON.parse(this.elem.getAttribute('data-language'));

    this.setSlideRatio();
    this.getContainerWidth();
  },

  setSlideRatio: function() {
    var slideWidth = this.elem.getAttribute('data-slide-width');
    var slideHeight = this.elem.getAttribute('data-slide-height');

    this.slideRatio = slideHeight / slideWidth;
  },

  getContainerWidth: function() {
    this.containerWidth = this.elem.clientWidth;
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {
    this.elem.setAttribute('data-init', '');
    Util.fire(this, ['addCaptionContainer', 'setDimensions', 'checkScroll', 'addButtons']);
  },

  addScrollTrack: function() {
    this.scrollTrack = this.createElem('div', 'slideshow-scroll-track');
    this.scrollTrack.innerHTML = this.elem.innerHTML;
    this.elem.innerHTML = '';
    this.elem.appendChild(this.scrollTrack);
  },

  addCaptionContainer: function() {
    // main caption container
    var captionContainer = this.createElem('div', 'caption-text slideshow-caption-container');

    // arrow and increment container
    var captionControls = this.createElem('div', 'slideshow-caption-controls');

    // prev/next
    var captionPrevNext = this.createElem('p', 'slideshow-caption-prev-next');
    this.captionPrev = this.createButton(this.language.prev, 'caption-previous');
    this.captionNext = this.createButton(this.language.next, 'caption-next');
    captionPrevNext.appendChild(this.captionPrev);
    captionPrevNext.appendChild(this.captionNext);
    captionControls.appendChild(captionPrevNext);

    // increment text
    this.slideCounter = this.createElem('p', 'slideshow-counter');
    captionControls.appendChild(this.slideCounter);
    captionContainer.appendChild(captionControls);

    // caption container
    this.slideCaption = this.createElem('div', 'slideshow-caption');
    this.slideCaption.setAttribute('aria-live', 'polite');
    this.slideCaption.setAttribute('aria-atomic', 'true');
    captionContainer.appendChild(this.slideCaption);

    // insert caption container after slideshow
    this.elem.parentNode.insertBefore(captionContainer, this.elem.nextSibling);
  },

  setDimensions: function() {
    Util.fire(this, ['setContainerDimensions', 'setSlideDimensions']);
  },

  setContainerDimensions: function() {
    this.elem.style.height = (this.containerWidth * this.slideRatio) + 'px';
    this.list.style.width = (this.containerWidth * this.numSlides) + 'px';
  },

  setSlideDimensions: function() {
    for (var i = 0, len = this.slides.length; i < len; i++) {
      this.slides[i].style.width = this.containerWidth + 'px';
      this.slides[i].style.height = (this.containerWidth * this.slideRatio) + 'px';
    }
  },

  addButtons: function() {
    attrs = {
      'aria-hidden': 'true',
      'tabindex': '-1'
    };

    this.slidePrev = this.createButton(this.language.prev, 'slideshow-previous', attrs);
    this.slideNext = this.createButton(this.language.next, 'slideshow-next', attrs);

    this.elem.appendChild(this.slidePrev);
    this.elem.appendChild(this.slideNext);
  },

  createButton: function(text, klass, attributes) {
    var newButton = document.createElement('button');
    newButton.setAttribute('class', klass);

    if (typeof attributes === 'object') {
      for (var attr in attributes) {
        newButton.setAttribute(attr, attributes[attr]);
      }
    }

    newButton.textContent = text;

    return newButton;
  },

  createElem: function(elem, klass) {
    var newDiv = document.createElement(elem);
    newDiv.setAttribute('class', klass);

    return newDiv;
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.slidePrev.addEventListener('click', this.prevSlide.bind(this));
    this.captionPrev.addEventListener('click', this.prevSlide.bind(this));

    this.slideNext.addEventListener('click', this.nextSlide.bind(this));
    this.captionNext.addEventListener('click', this.nextSlide.bind(this));

    window.addEventListener(
      'resize',
      Util.debounce(this.updateDimensions.bind(this), 50)
    );

    this.scrollTrack.addEventListener(
      'scroll',
      Util.debounce(this.checkScroll.bind(this), 50)
    );
  },

  checkScroll: function() {
    var scrollPos = Math.round(this.scrollTrack.scrollLeft / this.containerWidth);
    if (scrollPos !== this.current) this.updateCurrent(scrollPos);
  },

  updateCurrent: function(pos) {
    this.current = pos;
    Util.fire(this, ['updateCaption', 'updateCounter']);
  },

  updateDimensions: function() {
    Util.fire(this, ['getContainerWidth', 'setDimensions']);
    Util.scrollTo(this.scrollTrack, (this.containerWidth * this.current), 50);
  },

  updateCaption: function() {
    var caption = this.slides[this.current].querySelector('[data-slide-caption]');
    this.slideCaption.innerHTML = caption.innerHTML;
  },

  updateCounter: function() {
    this.slideCounter.innerHTML = this.slides[this.current].getAttribute('data-slide-increment');
  },

  prevSlide: function() {
    this.changeSlides(-1);
  },

  nextSlide: function() {
    this.changeSlides(1);
  },

  changeSlides: function(dir) {
    var newSlide = this.current + dir;
    this.elem.setAttribute('data-direction', (dir === 1) ? 'next' : 'prev');

    if (newSlide < 0) {
      newSlide = (this.numSlides - 1);
    } else if (newSlide > (this.numSlides - 1)) {
      newSlide = 0;
    }

    Util.scrollTo(this.scrollTrack, (this.containerWidth * newSlide), 200);
  }

};

module.exports = Slideshow;
