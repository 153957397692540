var Vars = require('./global-variables');

module.exports = {

  getMobileOperatingSystem: function() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (
      userAgent.match( /iPad/i ) ||
      userAgent.match( /iPhone/i ) ||
      userAgent.match( /iPod/i )
    ) {
      return 'iOS';
    } else if (
      userAgent.match( /Android/i )
    ) {
      return 'Android';
    } else {
      return 'unknown';
    }
  },

  getIsMobile: function() {
    var isMobile = false; //initiate as false
    // device detection
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
      isMobile = true;
    }
    return isMobile;
  },

  // extracts the file path from a background image computed style
  getStyleSrc: function(src) {
    return (src.match( /url\([^\)]+\)/gi ) || [''])[0].split(/[()'"]+/)[1];
  },

  // loops over an array of method names and executes them
  fire: function(instance, methods) {
    for (var i = 0, len = methods.length; i < len; i++) {
      instance[methods[i]].call(instance);
    }
  },

  // debounce function via: https://davidwalsh.name/javascript-debounce-function
  debounce: function(func, wait, immediate) {
    var timeout;

    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  },

  loadScript: function(src) {
    var scriptTag = document.createElement('script');
    var scripts = document.getElementsByTagName('script')[0];

    scriptTag.type = 'text/javascript';
    scriptTag.src = src;
    scripts.parentNode.insertBefore(scriptTag, scripts);
  },

  scrollTo: function(element, to, duration) {
    if (duration <= 0) return;
    var difference = to - element.scrollLeft;
    var perTick = difference / duration * 10;

    setTimeout(function() {
      element.scrollLeft = element.scrollLeft + perTick;
      if (element.scrollLeft === to) return;
      this.scrollTo(element, to, duration - 10);
    }.bind(this), 10);
  },

  guid: function() {
    var s4 = function() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };

    return s4() + s4() + s4();
  },

  focusRestrict: function(event, container, condition) {
    if (event.which === Vars.keys.TAB && condition) {
      var list = container.querySelectorAll('a, button, input, select, textarea, video, audio, iframe, embed, object');
      var focusableTmp = Array.prototype.slice.call(list);
      var focusable = [];

      // loop over list and filter out any elements with a tabindex of -1
      for (var i = 0, len = focusableTmp.length; i < len; i++) {
        if (focusableTmp[i].getAttribute('tabindex') !== '-1') {
          focusable.push(focusableTmp[i]);
        }
      }

      var listLength = focusable.length;
      var focused = document.activeElement;
      var focusIndex = focusable.indexOf(focused);
      var nextIndex;
      var manageFocus;

      // meet all the conditions!
      if (focusIndex < listLength - 1 && !event.shiftKey) {
        nextIndex = focusIndex + 1;
        manageFocus = false;
      } else if (focusIndex > 0 && event.shiftKey) {
        nextIndex = focusIndex -1;
        manageFocus = false;
      } else if (focusIndex === listLength -1 && !event.shiftKey) {
        nextIndex = 0;
        manageFocus = true;
      } else {
        nextIndex = listLength -1;
        manageFocus = true;
      }

      if (manageFocus) {
        focusable[nextIndex].focus();

        event.preventDefault();
      }
    }
  },

  // finds a specific dom element in the collection
  // and returns the array position
  getArrayPosition: function(array, elem) {
    for (var i = 0, len = array.length; i < len; i++) {
      if (array[i] === elem) return i;
    }
  },

  // returns the height of the tallest element in a collection
  returnTallest: function(arr) {
    var maxHeight = 0;

    for (var i = 0, len = arr.length; i < len; i++) {
      arr[i].style.height = null;

      var h = arr[i].offsetHeight;
      if (h > maxHeight) maxHeight = h;
    }

    return maxHeight;
  },

  // get an element's exact position
  // modified from: https://www.kirupa.com/html5/get_element_position_using_javascript.htm
  getPosition: function(el) {
    var xPos = 0;
    var yPos = 0;

    while (el) {
      xPos += (el.offsetLeft + el.clientLeft);
      yPos += (el.offsetTop + el.clientTop);

      el = el.offsetParent;
    }

    return {
      x: xPos,
      y: yPos
    };
  },

  scrollY: function() {
    return window.scrollY || window.pageYOffset;
  }

};
