var Util = require('../utilities');

Share = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

Share.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'addListeners']);
  },

  height: 255,
  width: 630,

  setVariables: function() {
    this.url = this.elem.getAttribute('href');
  },

  addListeners: function() {
    this.elem.addEventListener('click', this.openWindow.bind(this));
  },

  // opens a centered popup window
  openWindow: function(event) {
    var left = (screen.width/2)-(this.width/2);
    var top = (screen.height/2)-(this.height/2);

    window.open(this.url, '', 'width=' + this.width + ',height=' + this.height + ', top=' + top + ', left=' + left);

    event.preventDefault();
  }
};

module.exports = Share;
