var Util = require('../utilities');
var Vars = require('../global-variables');
var whatInput = require('what-input');

LinkDropdown = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

LinkDropdown.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  isOpen: true,

  setVariables: function() {
    this.list = this.elem.querySelector('[data-dropdown-list]');
    this.links = this.list.querySelectorAll('a');

    // set unique id on list if id doesn't already exist
    this.listId = this.list.getAttribute('id');

    if (!this.listId) {
      this.listId = 'dropdown-' + Util.guid();
      this.list.setAttribute('id', this.listId);
    }

    // get the language data attribute
    this.language = JSON.parse(this.elem.getAttribute('data-language'));
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {
    Util.fire(this, ['addToggle', 'toggleDropdown']);
  },

  addToggle: function() {
    this.toggle = document.createElement('button');
    this.toggle.setAttribute('aria-expanded', 'false');
    this.toggle.setAttribute('aria-controls', this.listId);
    this.toggle.classList.add('link-dropdown-toggle');
    this.toggle.textContent = this.language.label;

    this.elem.insertBefore(this.toggle, this.elem.firstChild);
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    // named event handlers for binding/unbinding
    this.onCloseHandler = this.closeDropdown.bind(this);
    this.onFocusRestrict = this.a11yFocus.bind(this);
    this.onDismiss = this.dismiss.bind(this);

    this.toggle.addEventListener('click', this.toggleDropdown.bind(this));
  },

  toggleEventListners: function(action) {
    document[action]('keydown', this.onCloseHandler);
    window[action]('keydown', this.onFocusRestrict);

    // click or touch outside dropdown closes it
    document.body[action]('click', this.onDismiss);
  },

  a11yFocus: function(event) {
    Util.focusRestrict(event, this.elem, this.isOpen);
  },

  closeDropdown: function() {
    if (this.isOpen && event.which === Vars.keys.ESC) this.toggleDropdown();
  },

  dismiss: function(event) {
    if (!event.target.closest('[data-module="link-dropdown"]')) this.toggleDropdown();
  },

  toggleDropdown: function() {
    this.isOpen = !this.isOpen;

    this.elem.setAttribute('data-is', (this.isOpen) ? 'open' : 'closed');

    for (var i = 0, len = this.links.length; i < len; i++) {
      this.links[i].setAttribute('tabindex', (this.isOpen) ? '0' : '-1');
    }

    this.toggleEventListners((this.isOpen) ? 'addEventListener' : 'removeEventListener');

    this.manageFocus();
  },

  manageFocus: function() {
    if (whatInput.ask() === 'keyboard') {
      if (this.isOpen) {
        this.links[0].focus();
      } else {
        this.toggle.focus();
      }
    }
  }

};

module.exports = LinkDropdown;
