var Util = require('../utilities');

ArticleGrid = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

ArticleGrid.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'addListeners', 'reSize']);
  },

  reBuild: function() {
    Util.fire(this, ['setVariables', 'setUp', 'reSize']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  lastFeature: null,
  mq: window.matchMedia('(min-width: 30em)'),

  setVariables: function() {
    this.list = this.elem.querySelector('[data-list]');
    this.items = this.list.querySelectorAll('[data-story], [data-feature]');
    this.stories = this.list.querySelectorAll('[data-story]');
    this.features = this.list.querySelectorAll('[data-feature]');
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {
    for (var i = 0, len = this.features.length; i < len; i++) {
      if (Util.getArrayPosition(this.items, this.features[i]) > 0) {
        this.lastFeature = this.features[i];
      }
    }

    if (this.lastFeature) {
      this.lastFeature.classList.add('-last');
      this.moveLastFeature();
    }
  },

  // moves second feature to third from last so it floats correctly
  moveLastFeature: function() {
    var newPos = (this.mq.matches) ? 2 : 0;

    this.list.insertBefore(
      this.lastFeature,
      this.stories[this.stories.length - newPos]
    );
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.mq.addListener(this.moveLastFeature.bind(this));

    window.addEventListener(
      'resize',
      Util.debounce(this.reSize.bind(this), 50)
    );

    window.pubSub.subscribe('fontsLoaded', this.reSize.bind(this));

    // subscribe to the `rebuildArticles` event
    // that can be called externally
    window.pubSub.subscribe('rebuildArticles', this.reBuild.bind(this));
  },

  reSize: function() {
    this.resetHeight();
    var maxHeight = Util.returnTallest(this.stories);

    // set all elements to the new max height
    var i;
    var len;

    for (i = 0, len = this.features.length; i < len; i++) {
      this.features[i].style.height = (maxHeight * 2) + 'px';
    }

    for (i = 0, len = this.stories.length; i < len; i++) {
      this.stories[i].style.height = maxHeight + 'px';
    }
  },

  // remove inline height so measurement can be taken
  resetHeight: function() {
    for (var i = 0, len = this.stories.length; i < len; i++) {
      this.stories[i].style.height = 'auto';
    }
  }

};

module.exports = ArticleGrid;
