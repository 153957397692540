
(function ($) {

  Drupal.behaviors.rotary_news_features = {
    attach: function (context, settings) {

      var faders = context.querySelectorAll(".fade-in");
      var sliders = context.querySelectorAll(".slide-in");

      var sectionOneOptions = {
          rootMargin: "-200px 0px 0px 0px"
      };

      var appearOptions = {
          threshold: 0,
          rootMargin: "0px 0px -250px 0px"
      };

      var appearOnScroll = new IntersectionObserver(function (
          entries,
          appearOnScroll
      ) {
          entries.forEach(function(entry) {
              if (!entry.isIntersecting) {
                  return;
              } else {
                  entry.target.classList.add("appear");
                  appearOnScroll.unobserve(entry.target);
              }
          });
      },
          appearOptions);

      faders.forEach(function(fader) {
          appearOnScroll.observe(fader);
      });

      sliders.forEach(function(slider) {
          appearOnScroll.observe(slider);
      });

    }
  };
})(jQuery);
