var Util = require('../utilities');
var Vars = require('../global-variables');

var Parallax = function(elem) {
  this.elem = elem;
  this.init();
};

Parallax.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  mq: window.matchMedia(Vars.mq.medium),
  mqMatches: null,

  setVariables: function() {
    this.layers = this.elem.querySelectorAll('[data-parallax-item]');
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {
    this.getPosition();

    if (this.mq.matches) {
      this.mqMatches = true;

      this.doParallax();
    } else {
      this.mqMatches = false;

      for (var i = 0, len = this.layers.length; i < len; i++) {
        this.prefixTransition(this.layers[i], null);
      }
    }
  },

  getPosition: function() {
    this.positionTop = Util.getPosition(this.elem).y;
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.mq.addListener(this.setUp.bind(this));

    window.addEventListener('resize', Util.debounce(this.getPosition.bind(this), 200));
  },

  doParallax: function() {
    if (this.mqMatches) {
      var topDistance = Util.scrollY() - this.positionTop;

      for (var i = 0, len = this.layers.length; i < len; i++) {
        var depth = this.layers[i].getAttribute('data-depth');
        var movement = -(topDistance * depth);
        var translate3d = 'translate3d(0, ' + movement + 'px, 0)';

        this.prefixTransition(this.layers[i], translate3d);
      }

      requestAnimationFrame(this.doParallax.bind(this));
    }
  },


  /*
    --------------------
    Utilities
    --------------------
  */

  prefixTransition: function(element, value) {
    element.style['-webkit-transform'] = value;
    element.style['-moz-transform'] = value;
    element.style['-ms-transform'] = value;
    element.style['-o-transform'] = value;
    element.style.transform = value;
  }

};

module.exports = Parallax;
