var Util = require('../utilities');
var Vars = require('../global-variables');

Carousel = function(elem) {
  this.elem = elem;
  this.init();
};

Carousel.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'toggleSetUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  buttonPrev: null,
  buttonNext: null,
  currPos: {
    first: null,
    second: null,
    last: null
  },
  mqSm: window.matchMedia(Vars.mq.small),
  mqMd: window.matchMedia(Vars.mq.medium),
  tallest: null,

  setVariables: function() {
    this.list = this.elem.querySelector('[data-carousel="list"]');
    this.items = this.list.querySelectorAll('[data-carousel="item"]');
    this.itemText = this.list.querySelectorAll('[data-carousel="text"]');

    this.numItems = this.items.length;
  },


  /*
    --------------------
    Set up
    --------------------
  */

  toggleSetUp: function() {
    if (this.mqMd.matches) {
      this.setUpLarge();
    } else if (this.mqSm.matches) {
      this.setUpSmall(60, 40);
    } else {
      this.setUpSmall(80, 20);
    }
  },

  setUpSmall: function(width, gutter) {
    this.list.style.height = null;
    this.list.style.width = ((width * this.numItems) + gutter) + 'vw';

    this.evenHeights();

    for (var i = 0, len = this.numItems; i < len; i++) {
      this.toggleItemInteraction(this.items[i], 'show');
    }
  },

  setUpLarge: function() {
    this.evenHeights();
    this.list.style.width = null;

    if (this.numItems > 1) {
      for (var i = 0, len = this.numItems; i < len; i++) {
        this.toggleItemInteraction(this.items[i], 'hide');
      }

      this.currPos.first = 0;
      this.currPos.second = 1;
      this.currPos.last = this.numItems - 1;

      this.toggleItemInteraction(this.items[this.currPos.first], 'show');
      this.items[this.currPos.first].setAttribute('data-item', 'first');

      this.items[this.currPos.second].setAttribute('data-item', 'second');
      this.items[this.currPos.last].setAttribute('data-item', 'last');

      this.addButtons();
    }
  },

  evenHeights: function() {

    // get tallest content container
    this.tallest = Util.returnTallest(this.itemText) + 'px';

    for (var i = 0, len = this.itemText.length; i < len; i++) {
      this.itemText[i].style.height = this.tallest;
    }

    this.list.style.height = (this.mqMd.matches) ? Util.returnTallest(this.items) + 'px' : null;
  },

  addButtons: function() {
    if (!this.buttonPrev) {
      this.buttonPrev = document.createElement('button');
      this.buttonPrev.classList.add('carousel-button-prev');
      this.buttonPrev.textContent = 'Previous';

      this.elem.insertBefore(this.buttonPrev, this.list);
      this.buttonPrev.addEventListener('click', this.prevItem.bind(this));
    }

    if (!this.buttonNext) {
      this.buttonNext = document.createElement('button');
      this.buttonNext.classList.add('carousel-button-next');
      this.buttonNext.textContent = 'Next';

      this.elem.appendChild(this.buttonNext);
      this.buttonNext.addEventListener('click', this.nextItem.bind(this));
    }
  },

  toggleItemInteraction: function(elem, state) {
    var list = elem.querySelectorAll('a, button, input, select, textarea');

    if (state === 'hide') {
      elem.setAttribute('aria-hidden', 'true');
    } else {
      elem.removeAttribute('aria-hidden');
    }

    for (var i = 0, len = list.length; i < len; i++) {
      list[i].setAttribute(
        'tabindex',
        (state === 'hide') ? '-1' : '0'
      );
    }
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {
    this.mqSm.addListener(this.toggleSetUp.bind(this));
    this.mqMd.addListener(this.toggleSetUp.bind(this));

    window.pubSub.subscribe('fontsLoaded', this.evenHeights.bind(this));
    window.addEventListener('resize', Util.debounce(this.evenHeights.bind(this), 100));
  },

  advanceCarousel: function(dir) {
    this.toggleItemInteraction(this.items[this.currPos.first], 'hide');
    this.items[this.currPos.first].removeAttribute('data-item');
    this.items[this.currPos.second].removeAttribute('data-item');
    this.items[this.currPos.last].removeAttribute('data-item');

    this.advanceItem('first', dir);
    this.advanceItem('second', dir);
    this.advanceItem('last', dir);

    this.toggleItemInteraction(this.items[this.currPos.first], 'show');
    this.items[this.currPos.first].setAttribute('data-item', 'first');
    this.items[this.currPos.first].setAttribute('data-direction', dir);
    this.items[this.currPos.second].setAttribute('data-item', 'second');
    this.items[this.currPos.last].setAttribute('data-item', 'last');
  },

  // pos: position in carousel (first/second/last)
  // dir: direction to move (next/prev)
  advanceItem: function(pos, dir) {
    var newPos;

    if (dir === 'next') {
      newPos = this.currPos[pos] + 1;
      if (newPos > (this.numItems - 1)) newPos = 0;
    } else {
      newPos = this.currPos[pos] - 1;
      if (newPos < 0) newPos = this.numItems - 1;
    }

    this.currPos[pos] = newPos;
  },

  nextItem: function(event) {
    this.advanceCarousel('next');
  },

  prevItem: function(event) {
    this.advanceCarousel('prev');
  }

};

module.exports = Carousel;
