var Util = require('../utilities');

ResponsiveVideo = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

ResponsiveVideo.prototype = {

  // list of video selectors
  selectors: [
    'iframe',
    'video',
    'object',
    'embed'
  ],

  init: function() {

    // loop over video selectors to find video
    for (var i = 0, len = this.selectors.length; i < len; i++) {
      this.video = this.elem.querySelector(this.selectors[i]);

      // if a video is found, run set up and exit loop
      if (this.video) {
        this.video.classList.add('responsive-video-media');
        Util.fire(this, ['measure', 'wrap']);

        return;
      }
    }
  },

  measure: function() {

    // gets the width and height of the video and
    // returns an aspect ratio

    var attrWidth = parseInt(this.video.getAttribute('width'), 10);
    var attrHeight = parseInt(this.video.getAttribute('height'), 10);

    var ratioWidth = !isNaN(attrWidth) ? attrWidth : 16;
    var ratioHeight = !isNaN(attrHeight) ? attrHeight : 9;

    this.aspectRatio = ratioHeight / ratioWidth;
  },

  wrap: function() {

    // wraps the video in a div container and adds the aspect ratio
    // as bottom padding (http://www.alistapart.com/articles/creating-intrinsic-ratios-for-video/)

    var wrapper = document.createElement('div');
    wrapper.classList.add('responsive-video-wrapper');
    wrapper.style.paddingTop = (this.aspectRatio * 100) + '%';

    // remove width and height to ensure element is fluid
    this.video.removeAttribute('width');
    this.video.removeAttribute('height');

    // append video to wrapper
    wrapper.appendChild(this.video);

    // prepend to container
    this.elem.insertBefore(wrapper, this.elem.firstChild);
  }

};

module.exports = ResponsiveVideo;
