/*
  --------------------
  ImpactStatistics
  Generic utility for triggering CSS animations when a container
  is scrolled into view. Uses waypoints.js.

  Required: data-module="impact-statistics"
  --------------------
*/
var Util = require('../utilities');

ImpactStatistics = function() {
  this.init();
};

ImpactStatistics.prototype = {

  init: function () {

    // get ID for element
    var containerImg = document.querySelectorAll(".layout-container.stat-background-img");
    var impactStatsContainer = document.getElementById('impactstat');
    var scrollPos = 0;
    var elements = document.getElementsByClassName("stat-set");
    var flagUp = document.querySelectorAll(".impact-statitics-bottom");
    var flagDown = document.querySelectorAll(".impact-statitics-top");
    var impactStatDivs = document.querySelectorAll(".impact-stat-list div");
    var animate = 'fade-up';

    function isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function checkPosition() {
      const visibleUp = isInViewport(flagUp.item(0)) ? true : false;
      const visibleDown = isInViewport(flagDown.item(0)) ? true : false;
      var visibleDivs = false;
      for (var i = 0; i < impactStatDivs.length; i++) {
        if (isInViewport(impactStatDivs.item(i))) {
          visibleDivs = true;
        }
      }

      if (visibleUp) {
        animate = 'fade-down';
      }
      if (visibleDown) {
        animate = 'fade-up';
      }
      var windowY = window.scrollY;
      if (visibleUp || visibleDown || visibleDivs) {
        setTimeout(function () {
          elements.item(0).setAttribute("data-is", "visible");
          elements.item(1).setAttribute("data-is", "visible");
          elements.item(2).setAttribute("data-is", "visible");
        }, 250);
      }
      else {
        elements.item(0).setAttribute("data-animate", animate);
        elements.item(1).setAttribute("data-animate", animate);
        elements.item(2).setAttribute("data-animate", animate);
        setTimeout(function () {
          elements.item(0).setAttribute("data-is", "ready");
          elements.item(1).setAttribute("data-is", "ready");
          elements.item(2).setAttribute("data-is", "ready");
        }, 250);
      }
      scrollPos = windowY;
    }

    checkPosition();
    window.addEventListener('scroll', checkPosition);

    function outerHeight(element) {
      const height = element.offsetHeight,
        style = window.getComputedStyle(element);

      return ['top', 'bottom']
        .map(function(side) { return parseInt(style['margin-' + side], 10) })
        .reduce(function(total, side) { return total + side, height })
    }

    function resizeCards() {
      var maxSize = 20;
      var boxes = document.getElementsByClassName("stat-wrapper-description");
      Array.prototype.forEach.call(boxes, function (box, index) {
        var titleh = outerHeight(box.querySelectorAll('.impact-stat-description')[0]);
        var descriptionh = outerHeight(box.querySelectorAll('.text-stat-title')[0]);
        var totalh = titleh + descriptionh + 20;
        if (totalh > maxSize) {
          maxSize = totalh;
        }
      })
      Array.prototype.forEach.call(boxes, function (box, index) {
        box.style.height = maxSize + "px";
      });
    }
    window.addEventListener('resize', resizeCards);

    function resizeTitle() {
      var slides = document.querySelectorAll('.impact-stat-number span');
      Array.prototype.forEach.call(slides, function (slide, index) {
        if (slide.offsetWidth > 121) {
          slide.parentElement.parentElement.style.flexBasis = '60%';
        }
      });
    }
    window.addEventListener('resize', resizeTitle);
    resizeCards();
    resizeTitle();
  }

};

module.exports = ImpactStatistics;
