module.exports = {

  mq: {
    small: '(min-width: 30em)', // 480px
    medium: '(min-width: 47.5em)', // 760px
    navigation: '(min-width: 62.5em)' // 1000px
  },

  keys: {
    DOWN: 40,
    RIGHT: 39,
    UP: 38,
    LEFT: 37,
    ESC: 27,
    TAB: 9
  },

  nav: document.querySelector('[data-nav]')

};
