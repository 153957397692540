/*
  --------------------
  Styles

  Simple script that moves any `<style>` blocks into the `<head>` so they don't interfere with :first-child and other pseudo class selectors.
  --------------------
*/

Styles = function(elem) {
  document.head.appendChild(elem);
};

module.exports = Styles;
