/*
  --------------------
  Page Hero

  Handles progressive loading of background image as well as loading and playing ambient video if the browser requirements allow.

  The Play/Pause video button sets a session variable and prevents subsequent videos from loading and auto-playing if one has been paused -- this assumes that the visitor does not want videos playng for any reason.

  Features: scrollfade
  --------------------
*/

var Util = require('../utilities');

Hero = function(elem, features) {
  this.elem = elem;
  this.features = features;
  this.init();
};

Hero.prototype = {

  init: function() {
    Util.fire(this, ['setVariables', 'setDimensions', 'setUp', 'addListeners']);
  },


  /*
    --------------------
    Variables
    --------------------
  */

  currentSlide: null,
  previousSlide: null,
  loadTimer: null,
  loadTimeout: 2000,
  mediaPlaying: false,
  textHeight: null,
  scrollPaused: false,
  slidesLoaded: false,
  slidePos: 0,
  slideTimer: null,
  slideTiming: 5000, // ms
  videoLoaded: false,

  setVariables: function() {
    this.heroText = this.elem.querySelector('.hero-text');
    this.imgContainer = this.elem.querySelector('.hero-background');
    this.placeholderContainer = this.elem.querySelector('.hero-background-placeholder');
    this.videoElem = this.elem.querySelector('.hero-video');

    this.image = this.isImage();
    this.fullHeight = this.elem.classList.contains('-tall');
    this.mobile = this.isMobile();
    this.video = this.isVideo();
    this.slideshow = this.features.includes('slideshow');

    if (this.image) {
      this.imgUrl = this.backgroundImageSrc();
    }

    if (this.video) {
      this.webmSrc = this.videoElem.getAttribute('data-webm');
      this.mp4Src = this.videoElem.getAttribute('data-mp4');
    } else if (this.videoElem && this.mobile) {

      // remove video element on mobile devices
      this.videoElem.parentNode.removeChild(this.videoElem);
    }

    if (this.slideshow) {
      this.slides = this.elem.querySelectorAll('[data-hero-slide]');
    }

    if (this.video || this.slideshow) {
      this.mediaPlaying = (sessionStorage['play-heros'] === 'false') ? false : true;

      this.pauseButton = this.elem.querySelector('.hero-pause');
      this.pauseText = this.pauseButton.textContent;
      this.playText = this.pauseButton.getAttribute('data-toggle-text');
    }
  },

  setDimensions: function() {

    // effectively the header height
    this.heroOffset = this.elem.offsetTop;

    // window height minus header
    this.heroHeight = window.innerHeight - this.heroOffset;
  },

  backgroundImageSrc: function() {
    var imgSrc = window
      .getComputedStyle(this.imgContainer, null)
      .getPropertyValue('background-image');

    return Util.getStyleSrc(imgSrc);
  },

  isImage: function() {
    return (this.imgContainer) ? true : false;
  },

  isMobile: function() {
    return (
      // janky but simple way of filtering out mobile os's that don't support autoplay
      Util.getMobileOperatingSystem() === 'iOS' ||
      Util.getMobileOperatingSystem() === 'Android' ||

      // also don't load up video on smaller screens
      window.matchMedia('(max-width: 799px)').matches
    ) ? true : false;
  },

  isVideo: function() {
    return (
      this.videoElem &&
      !this.mobile
    ) ? true : false;
  },


  /*
    --------------------
    Set up
    --------------------
  */

  setUp: function() {

    // set initial attribute to hide image before it's done loading
    this.elem.setAttribute('data-is', 'loading');

    if (this.video) {
      this.elem.setAttribute('data-video', '');

      // don't load or play video if `play-heros` variable is set to `false`
      if (!this.mediaPlaying) {
        this.pauseButton.textContent = this.playText;
      } else {
        this.loadVideo();
      }
    } else if (this.slideshow) {
      this.elem.setAttribute('data-slideshow', '');

      if (!this.mediaPlaying) {
        this.pauseButton.textContent = this.playText;
      } else {
        this.setUpSlides();
        this.cycleSlides();
      }
    }

    // for full-height images, programatically set height
    if (this.fullHeight) this.setFullHeight();
  },

  loadVideo: function() {
    if (this.webmSrc) {
      this.videoElem.appendChild(this.createSource(this.webmSrc, 'video/webm'));
    }

    if (this.mp4Src) {
      this.videoElem.appendChild(this.createSource(this.mp4Src, 'video/mp4'));
    }

    this.videoLoaded = true;
  },

  createSource: function(src, type) {
    var videoSource = document.createElement('source');
    videoSource.setAttribute('src', src);
    videoSource.setAttribute('type', type);

    return videoSource;
  },

  setUpSlides: function() {
    this.slidesLoaded = true;

    for (var i = 0, len = this.slides.length; i < len; i++) {
      this.slides[i].setAttribute('data-hero-slide', 'ready');
    }
  },

  setFullHeight: function() {

    // set the hero height
    var windowHeight = window.innerHeight;
    this.textHeight = this.heroText.clientHeight;
    var windowMinusHeader = windowHeight - this.heroOffset;
    var heroHeight = (windowMinusHeader > this.textHeight) ? windowMinusHeader : this.textHeight;
    this.elem.style.height = heroHeight + 'px';

    // set the background height to the taller of the size of the screen or the size of the hero
    var heroSetHeight = heroHeight + this.heroOffset;
    var backgroundHeight = (windowHeight > heroSetHeight) ? windowHeight : heroSetHeight;

    if (this.placeholderContainer) this.placeholderContainer.style.height = backgroundHeight + 'px';

    // if this is a slideshow, also set the height of the slides
    if (this.slideshow) {
      for (var i = 0, len = this.slides.length; i < len; i++) {
        this.slides[i].style.height = backgroundHeight + 'px';
      }

    // or just set the single background image
    } else {
      this.imgContainer.style.height = backgroundHeight + 'px';
    }
  },


  /*
    --------------------
    Events
    --------------------
  */

  addListeners: function() {

    // add listener for scrollfade
    if (
      this.features.includes('scrollfade') &&
      this.textHeight <= this.heroHeight
    ) {
      window.addEventListener(
        'scroll',
        Util.debounce(this.fadeText.bind(this), 10)
      );
    }

    // video event listeners
    if (this.video) {
      this.loadImage();

      document.addEventListener(
        'visibilitychange',
        this.handleVisibilityChange.bind(this)
      );

      window.addEventListener(
        'scroll',
        Util.debounce(this.scrollToggle.bind(this), 10)
      );

      this.pauseButton.addEventListener(
        'click',
        this.clickToggle.bind(this)
      );

    // slideshow event listeners
    } else if (this.slideshow) {
      this.loadImage();

      window.addEventListener(
        'scroll',
        Util.debounce(this.scrollToggle.bind(this), 10)
      );

      this.pauseButton.addEventListener(
        'click',
        this.clickToggle.bind(this)
      );

    // start loading process for image
    } else if (this.image) {
      this.loadImage();

    // if text-only, set a short timeout and fire
    // loaded function to transition text
    } else {
      window.setTimeout(this.loaded.bind(this), 100);
    }

    // only resize full height heroes on orientation change
    // resize triggered by scrolling is buggy looking when the UI
    // minimizes on mobile devices on scroll
    if (this.fullHeight) {
      window.pubSub.subscribe(
        'fontsLoaded',
        this.setFullHeight.bind(this)
      );

      if (this.mobile) {
        window.addEventListener(
          'orientationchange',
          function() {
            setTimeout(this.setFullHeight.bind(this), 100);
          }.bind(this)
        );
      } else {
        window.addEventListener(
          'resize',
          Util.debounce(function() {
            this.setDimensions();
            this.setFullHeight();
          }.bind(this), 10)
        );
      }
    }
  },

  handleVisibilityChange: function() {
    if (document.hidden) {
      this.videoElem.pause();
    } else {
      if (this.mediaPlaying && Util.scrollY() < this.heroOffset) {
        this.videoElem.play();
      }
    }
  },

  loadImage: function() {
    // start a timer that triggers the load event if
    // it takes too long for the image to finish loading
    this.loadTimer = window.setTimeout(this.loaded.bind(this), this.loadTimeout);

    var img = new Image();

    // preload image
    img.src = this.imgUrl;
    img.onload = function() {

      // clear the timeout if the image finishes loading
      window.clearTimeout(this.loadTimer);

      this.loaded();
    }.bind(this);
  },

  loaded: function() {
    this.elem.setAttribute('data-is', 'loaded');
  },

  // fades out the text on tall heroes as the page is scrolled
  fadeText: function() {
    var scrollPos = Util.scrollY();
    var fadeDistance = this.heroHeight / 4;

    this.heroText.classList[(scrollPos >= fadeDistance) ? 'add' : 'remove']('-scrolled');
  },

  // generic run/pause utilities
  run: function() {
    this.pauseButton.textContent = this.pauseText;
    this.mediaPlaying = true;

    // set session variable
    sessionStorage['play-heros'] = 'true';
  },

  pause: function() {
    this.pauseButton.textContent = this.playText;
    this.mediaPlaying = false;

    // set session variable
    sessionStorage['play-heros'] = 'false';
  },

  scrollToggle: function() {
    var scrollPos = Util.scrollY();

    // once the page has been scrolled, pause the video/slideshow
    if (scrollPos > this.heroOffset) {
      if (!this.scrollPaused) {
        this.scrollPaused = true;
        this.elem.setAttribute('data-scroll-pause', '');
      }

      if (this.video) {
        if (!this.videoElem.paused) this.videoElem.pause();

      } else {
        if (this.slideTimer) {
          window.clearTimeout(this.slideTimer);
          this.slideTimer = null;
        }

      }
    } else {
      if (this.scrollPaused) {
        this.scrollPaused = false;
        this.elem.removeAttribute('data-scroll-pause');
      }

      if (this.video) {
        if (this.videoElem.paused && this.mediaPlaying) this.videoElem.play();
      } else {
        if (!this.slideTimer && this.mediaPlaying) this.cycleSlides();
      }
    }
  },

  clickToggle: function() {
    if (this.mediaPlaying) {
      this.pause();

      if (this.video) {
        this.videoElem.pause();
      } else {
        window.clearTimeout(this.slideTimer);
      }
    } else {

      // check to see if the slides have been set up
      if (this.video && !this.videoLoaded) {
        this.loadVideo();

      // check to see if the slides have been set up
      } else if (this.slideshow && !this.slidesLoaded) {
        this.setUpSlides();
      }

      this.run();

      if (this.video) {
        this.videoElem.play();
      } else {
        this.cycleSlides();
      }
    }
  },

  cycleSlides: function() {
    if (this.previousSlide) this.previousSlide.removeAttribute('data-is');

    if (this.slidePos > (this.slides.length - 1)) {
      this.slidePos = 0;
    }

    this.previousSlide = this.currentSlide;
    if (this.previousSlide) this.previousSlide.setAttribute('data-is', 'previous');

    this.currentSlide = this.slides[this.slidePos];
    this.currentSlide.setAttribute('data-is', 'current');

    this.slidePos++;

    this.slideTimer = window.setTimeout(
      this.cycleSlides.bind(this),
      this.slideTiming
    );
  }

};

module.exports = Hero;
